import to from 'await-to-js';
import { actions as authActions } from './auth.actions';
import { authService } from '../../services/auth/authApiService';

const login = (body) => async (dispatch) => {
  dispatch(authActions.getAuthInitialized());

  const [err, data] = await to(authService.login(body));
  if (err) {
    return;
  }

  dispatch(authActions.getAuthSuccessful(data));
};

const refresh = (body) => async (dispatch) => {
  dispatch(authActions.refreshInitialized());

  const [err, data] = await to(authService.refresh(body));
  if (err) {
    dispatch(authActions.logoutSuccessful());
    return;
  }

  dispatch(authActions.refreshSuccessful(data));
};

const logout = () => async (dispatch) => {
  dispatch(authActions.logoutInitialized());

  const [err, data] = await to(authService.logout());
  if (err) {
    return;
  }

  dispatch(authActions.logoutSuccessful(data));
};

export const thunks = {
  login,
  refresh,
  logout,
};
