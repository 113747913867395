import { Box, Button } from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import { useState } from 'react';
import { CustomCandidateText } from '../../../../components/TextStyle/component/index.style';

function Resume({ resume }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <Box sx={{ pb: { xs: '20px', md: '100px' } }}>
      <CustomCandidateText
        sx={{
          textAlign: 'start',
          pb: '10px',
          fontSize: { md: '20px' },
          lineHeight: { md: '24px' },
        }}
      >
        {' '}
        Resume
      </CustomCandidateText>
      <Box
        sx={{
          width: { xs: '286px', md: '716px' },
          height: { xs: '429px', md: '1024px' },
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            padding: '0',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            display: { xs: 'flex', md: 'none' },
            flexDirection: 'column',
          }}
        >
          <Document file={resume} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} width={286} height={429} />
          </Document>
          <Box sx={{ zIndex: 10, display: 'flex' }}>
            <Button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
              Previous
            </Button>
            <p style={{ margin: 'auto' }}>
              {pageNumber || (numPages ? 1 : '--')}
              {' '}
              /
              {numPages || '--'}
            </p>
            <Button
              type="button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
            >
              Next
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            padding: '0',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            display: { xs: 'none', md: 'flex' },
            flexDirection: 'column',
          }}
        >
          <Document file={resume} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} width={716} height={1012} />
          </Document>
          <Box sx={{ zIndex: 10, display: 'flex' }}>
            <Button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
              Previous
            </Button>
            <p style={{ margin: 'auto' }}>
              {pageNumber || (numPages ? 1 : '--')}
              {' '}
              /
              {numPages || '--'}
            </p>
            <Button
              type="button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Resume;
