import { createAction } from '@reduxjs/toolkit';

const getProfileInitialized = createAction('getProfile/initialized');
const getProfileSuccessful = createAction('getProfile/successful');

const updateProfileInitialized = createAction('updateProfile/initialized');
const updateProfileSuccessful = createAction('updateProfile/successful');

const regInitialized = createAction('register/initialized');
const regSuccessful = createAction('register/successful');

const updateProfileLogoInitialized = createAction(
  'updateProfileLogo/initialized',
);
const updateProfileLogoSuccessful = createAction(
  'updateProfileLogo/successful',
);

const recoveryInitialized = createAction('recoveryPassword/initialized');
const recoverySuccessful = createAction('recoveryPassword/successful');

const newPasswordInitialized = createAction('newPassword/initialized');
const newPasswordSuccessful = createAction('newPassword/successful');

export const actions = {
  getProfileInitialized,
  getProfileSuccessful,
  updateProfileInitialized,
  updateProfileSuccessful,
  updateProfileLogoInitialized,
  updateProfileLogoSuccessful,
  regInitialized,
  regSuccessful,
  recoveryInitialized,
  recoverySuccessful,
  newPasswordInitialized,
  newPasswordSuccessful,
};
