import React from 'react';
import { CustomTypographyTitle } from '../../../../index.style';
import { CustomTitleForJob } from '../../../../../../components/TextStyle/component/index.style';

function DescItem({ label, value }) {
  return (
    <>
      <CustomTypographyTitle pb="10px" sx={{ fontSize: { xs: '18px', md: '22px' } }}>
        {label}
      </CustomTypographyTitle>
      <CustomTitleForJob sx={{
        mb: '10px',
        fontSize: { xs: '18px', md: '20px' },
        lineHeight: { xs: '18px', md: '24px' },
      }}
      >
        {value}
      </CustomTitleForJob>
    </>
  );
}
export default DescItem;
