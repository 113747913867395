import { styled } from '@mui/material';
import CustomFontFamily from '../../components/TextStyle/index.style';

export const CustomTitleScene = styled(CustomFontFamily)({
  p: '10px',
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '36px',
  textAlign: 'start',
  color: '#151515',
  textTransform: 'capitalize',
});

export const CustomTitleForVideJob = styled(CustomFontFamily)({
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '12px',
  lineHeight: '18px',
  textAlign: 'center',
  color: '#CBCDD0',
});
