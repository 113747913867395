import { Typography, styled } from '@mui/material';
import { MAIN_COLOR } from '../../config/config';

const CustomFontFamily = styled(Typography)({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  color: MAIN_COLOR,
});

export default CustomFontFamily;
