import { styled } from '@mui/material';
import CustomFontFamily from '../../../../components/TextStyle/index.style';
import { SECONDARY_COLOR } from '../../../../config/config';

export const FormTitleForResume = styled(CustomFontFamily)({
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '22px',
  textAlign: 'center',
  color: '#000',
});

export const FormDescription = styled(CustomFontFamily)({
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '16px',
  /* identical to box height, or 157% */
  textAlign: 'center',
  color: SECONDARY_COLOR,
});
