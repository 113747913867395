import * as apiService from '../utils/apiService';
import {
  APPROVE_URL,
  CREATE_APP_URL,
  DECLINE_URL,
  DELETE_URL,
  GET_ALL_URL,
  GET_APP_URL,
  UPDATE_APP_URL,
  UPLOAD_RESUME_URL,
  RATING_URL,
} from './constants';

export class CandidateService {
  async getAll(id) {
    const { data } = await apiService.axiosPrivate.get(`${GET_ALL_URL}${id}`);

    return data;
  }

  async get(id) {
    const { data } = await apiService.axiosPublic.get(`${GET_APP_URL}${id}`);

    return data;
  }

  async approve(id) {
    const { data } = await apiService.axiosPrivate.get(`${APPROVE_URL}${id}`);

    return data;
  }

  async decline(id) {
    const { data } = await apiService.axiosPrivate.get(`${DECLINE_URL}${id}`);

    return data;
  }

  async delete(id) {
    const { data } = await apiService.axiosPrivate.get(`${DELETE_URL}${id}`);

    return data;
  }

  async create(id, body) {
    const { data } = await apiService.axiosPublic.post(
      `${CREATE_APP_URL}${id}`,
      body,
    );

    return data;
  }

  async uploadResume(file, id) {
    const { data } = await apiService.axiosPublic.patch(
      `${UPLOAD_RESUME_URL}${id}`,
      file,
    );

    return data;
  }

  async update(id, body) {
    const { data } = await apiService.axiosPublic.patch(
      `${UPDATE_APP_URL}${id}`,
      body,
      {
        formVideo: true,
      },
    );

    return data;
  }

  async updateRating(id, body) {
    const { data } = await apiService.axiosPrivate.patch(
      `${RATING_URL}${id}`,
      body,
    );

    return data;
  }
}
export const candidateService = new CandidateService();
