import * as apiService from '../utils/apiService';
import { INIT_URL, MERGE_URL, UPLOAD_URL } from './constants';

export class VideoService {
  async init() {
    const { data } = await apiService.axiosPublic.get(INIT_URL, {
      formVideo: true,
    });

    return data;
  }

  async upload(id, file, formVideo) {
    const { data } = await apiService.axiosPublic.post(
      `${UPLOAD_URL}${id}`,
      file,
      {
        formVideo,
      },
    );

    return data;
  }

  async mergeVideo(id, formVideo) {
    const { data } = await apiService.axiosPublic.get(`${MERGE_URL}${id}`, {
      formVideo,
    });

    return data;
  }
}
export const videoService = new VideoService();
