import React from 'react';
import { Box, Grid } from '@mui/material';
import { Formik } from 'formik';
import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CInput from '../../components/CInput';
import CButton from '../../components/СButton';
import message from './img/message.svg';
import pass from './img/password.svg';
import {
  LoginLinkA,
  LoginLinkS,
} from '../../components/LoginContainer/index.style';
import { ValidationSchema } from './SignUpValidationSchemas';
import { thunksProfile } from '../../store/profile/profile.thunks';

function SignUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Box>
      <Box
        sx={{
          position: 'absolute',
          opacity: '0.6',
          zIndex: 10,
          top: '-47px',
          left: 0,
        }}
      />
      <Formik
        initialValues={{
          name: '',
          companyName: '',
          email: '',
          password: '',
          confirmPassword: '',
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values) => {
          dispatch(thunksProfile.register(values));
          // TODO: need show alert after success registration
          navigate('./login', { replace: true });
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <CInput
              placeholder="Name"
              sx={{ mb: '11px' }}
              type="name"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              error={errors.name}
            />
            <CInput
              placeholder="Company Name"
              sx={{ mb: '11px' }}
              type="companyName"
              name="companyName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.companyName}
            />
            <CInput
              placeholder="Email"
              sx={{ mb: '11px' }}
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={errors.email}
              InputProps={{
                startAdornment: (
                  <IconButton sx={{ left: '8px', pr: '20px' }}>
                    <img src={message} alt="message" />
                  </IconButton>
                ),
              }}
            />
            <CInput
              placeholder="Password"
              sx={{ mb: '11px' }}
              type="password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              error={errors.password}
              InputProps={{
                startAdornment: (
                  <IconButton sx={{ left: '8px', pr: '20px' }}>
                    <img src={pass} alt="message" />
                  </IconButton>
                ),
              }}
            />
            <CInput
              placeholder="Confirm Password"
              sx={{ mb: '12px' }}
              type="password"
              name="confirmPassword"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.confirmPassword}
              value={values.confirmPassword}
              InputProps={{
                startAdornment: (
                  <IconButton sx={{ left: '8px', pr: '20px' }}>
                    <img src={pass} alt="message" />
                  </IconButton>
                ),
              }}
            />
            <CButton type="submit" disabled={isSubmitting} height="48px">
              Sign up
            </CButton>
          </form>
        )}
      </Formik>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ my: '12px' }}
      >
        <Grid item xs={12} mt={2} sx={{ mt: '0px' }}>
          <LoginLinkA>Already have an account? </LoginLinkA>
          <Link to="/login" variant="body2" style={{ textDecoration: 'none' }}>
            <LoginLinkS>Login</LoginLinkS>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SignUp;
