import { Button, Box } from '@mui/material';
import React from 'react';
import { CustomFooter } from '../../index.style';

function ItemFooter({
  name, children, onClick, color, iconcolor,
}) {
  return (
    <Button
      onClick={onClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        color: '#fff',
      }}
    >
      <Box sx={{ height: '35px', color: iconcolor }}>
        {children}
        {' '}
      </Box>
      <CustomFooter sx={{ color }}>
        {' '}
        {name}
      </CustomFooter>
    </Button>
  );
}

export default ItemFooter;
