import { styled } from '@mui/material';
import CustomFontFamily from '../TextStyle/index.style';

export const LoginTitle = styled(CustomFontFamily)({
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '22px',
  textAlign: 'center',
  textTransform: 'uppercase',
  color: '#151515',
});

export const LoginLink = styled('span')({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '18px',
  /* identical to box height, or 157% */
  textAlign: 'center',
  color: '#132BFF',
});
export const LoginLinkA = styled(LoginLink)({
  textDecorationLine: 'none',
  color: '#989A9E',
});
export const LoginLinkS = styled(LoginLink)({
  textDecoration: 'none',
  color: '#151515',
});

export const LoginTitleDesk = styled(CustomFontFamily)({
  fontWeight: '700',
  fontSize: '32px',
  lineHeight: '32px',
  textAlign: 'center',
  textTransform: 'uppercase',
  color: '#151515',
});

export const LoginLinkDesk = styled('span')({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '22px',
  /* identical to box height, or 157% */
  textAlign: 'center',
  color: '#132BFF',
});
export const LoginLinkADesk = styled(LoginLink)({
  textDecorationLine: 'none',
  color: '#989A9E',
});
export const LoginLinkSDesk = styled(LoginLink)({
  textDecoration: 'none',
  color: '#151515',
});
