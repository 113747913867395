import CustomFontFamily from '../../../../../../components/TextStyle/index.style';

function Finish() {
  return (
    <>
      <CustomFontFamily
        sx={{
          fontWeight: '700',
          fontSize: { xs: '16px', md: '24px' },
          color: '#000000',
        }}
      >
        All Done
      </CustomFontFamily>
      <CustomFontFamily
        sx={{
          fontWeight: '400',
          fontSize: { xs: '12px', md: '20px' },
          color: '#989A9E',
        }}
      >
        Your responses has been submitted to the company.
      </CustomFontFamily>
    </>
  );
}

export default Finish;
