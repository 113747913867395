import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import HeaderMobile from '../HeaderMobile';
import HeaderDesktop from '../HeaderDesktop';

function MainContainer() {
  return (
    <Box
      sx={{
        p: 0,
        display: 'flex',
        overflow: { md: 'hidden' },
        flexDirection: { xs: 'column', md: 'row' },
        height: '100%',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          zIndex: '10',
          opacity: '0.5',
          top: { xs: '-47', md: '0px' },
        }}
      />
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <HeaderMobile />
      </Box>
      <Box
        sx={{
          display: { xs: 'none', md: 'flex' },
          width: '116px',
          justifyContent: 'center',
        }}
      >
        <HeaderDesktop />
      </Box>
      <Box sx={{ height: '100%', width: '100%', overflowY: 'scroll' }}>
        <Outlet />
      </Box>
    </Box>
  );
}

export default MainContainer;
