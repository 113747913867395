import axios from 'axios';
import { BASE_URL } from '../../core/apiConstants';
import { thunks } from '../../store/auth/auth.thunks';
import { addError } from '../../store/base/base.thunks';
import { actions as loadingActions } from '../../store/base/base.actions';
import { store } from '../../store/store';

export const axiosPublic = axios.create({ baseURL: BASE_URL });
export const axiosPrivate = axios.create({ baseURL: BASE_URL });

const responseErrorInterseptor = (error) => {
  const { message } = error.response.data;
  store.dispatch(addError(message));
  store.dispatch(loadingActions.loadingFinish());

  return Promise.reject(error);
};

const responseInterseptor = (response) => {
  if (!response.config.formVideo) {
    store.dispatch(loadingActions.loadingFinish());
  }

  return response;
};

axiosPrivate.interceptors.response.use(
  responseInterseptor,
  responseErrorInterseptor,
);

axiosPublic.interceptors.response.use(
  responseInterseptor,
  responseErrorInterseptor,
);

axiosPublic.interceptors.request.use((request) => {
  if (!request.formVideo) {
    store.dispatch(loadingActions.loadingStart());
  }

  return request;
});

axiosPrivate.interceptors.request.use(
  async (config) => {
    store.dispatch(loadingActions.loadingStart());

    if (store?.getState()?.auth?.access_token) {
      const expireTime = store.getState().auth?.expires_at;
      const refreshToken = store.getState().auth?.refresh_token;

      if (expireTime * 1000 < Date.now()) {
        await store.dispatch(thunks.refresh({ refresh_token: refreshToken }));
      }
      if (config?.headers) {
        config.headers.authorization = `Bearer ${
          store?.getState()?.auth?.access_token
        }`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error),
);
