import NavigateNext from '@mui/icons-material/NavigateNext';
import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CustomDescriptionForJob,
  CustomTitleForJob,
} from '../../../../components/TextStyle/component/index.style';
import { MAIN_COLOR } from '../../../../config/config';

function JobItem({ job }) {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        background: '#FFFFFF',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '5px',
        px: '10px',
        py: '15px',
        mb: '15px',
        textDecoration: 'none',
      }}
      onClick={() => navigate(`/app/candidates/all/${job.id}`)}
    >
      <Box>
        <CustomTitleForJob>
          {' '}
          {job.title}
        </CustomTitleForJob>
        <CustomDescriptionForJob>
          {job.stats.scheduledInterview}
          {' '}
          /
          {' '}
          {job.stats.ungraded}
        </CustomDescriptionForJob>
      </Box>
      <Box sx={{
        color: '#CBCDD0', height: '37px', alignItems: 'center', display: 'flex',
      }}
      >
        <NavigateNext sx={{
          fontSize: '37px',
          '&:hover': {
            boxShadow: 'none',
            color: MAIN_COLOR,
          },
          '&:active': {
            boxShadow: 'none',
            color: '#FF0202',
          },
        }}
        />
      </Box>
    </Box>
  );
}

export default JobItem;
