import React from 'react';
import CustomInput from './index.style';

function CInput({ error, ...fieldProps }) {
  return (
    <CustomInput {...fieldProps} error={!!error} fullWidth color="success" />
  );
}

export default CInput;
