import { Button, Box } from '@mui/material';
import { SECONDARY_COLOR } from '../../../../config/config';
import ResetIcon from './component/ResetIcon';

function ResetButton({ onClick }) {
  return (
    <Button
      onClick={onClick}
      component="span"
      sx={{
        fontWeight: 700,
        fontSize: '10px',
        color: SECONDARY_COLOR,
        background: 'rgba(80, 81, 84, 0.8)',
        '&:hover': {
          background: 'rgba(80, 81, 84, 0.8)',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mr: '5px',
        }}
      >
        <ResetIcon />
        {/* <UploadIcon width={16} height={16} /> */}
      </Box>
      Reset
    </Button>
  );
}

export default ResetButton;
