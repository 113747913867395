import to from 'await-to-js';
import { actions as formActions } from './form.actions';
import { jobApiService } from '../../services/job/jobApiService';
import { candidateService } from '../../services/candidates/candidateApiService';
import { thunks as videoThunks } from '../video/video.thunks';
import { thunks as candidateThunks } from '../candidates/candidates.thunks';

export const init = (jobId) => async (dispatch) => {
  dispatch(formActions.formInitialized());

  const [err, job] = await to(jobApiService.getJobInfo(jobId));

  if (err) {
    return;
  }

  const steps = [];

  steps.push({
    type: 'intro',
    video: job.introductionVideo,
    description: job.introductionDescription,
  });

  if (job.explainerEnabled) {
    steps.push({
      type: 'explainer',
      video: job.explainerVideo,
      description: job.explainerDescription,
    });
  }

  if (job.firstEnabled) {
    steps.push({
      type: 'record',
      param: 'firstQuestion',
      video: job.firstQuestion,
      description: job.firstDescription,
    });
  }

  if (job.secondEnabled) {
    steps.push({
      type: 'record',
      param: 'secondQuestion',
      video: job.secondQuestion,
      description: job.secondDescription,
    });
  }

  if (job.thirdEnabled) {
    steps.push({
      type: 'record',
      param: 'thirdQuestion',
      video: job.thirdQuestion,
      description: job.thirdDescription,
    });
  }
  if (job.fourthEnabled) {
    steps.push({
      type: 'record',
      param: 'fourthQuestion',
      video: job.fourthQuestion,
      description: job.fourthDescription,
    });
  }

  steps.push({ type: 'almost-done' });
  steps.push({ type: 'done' });

  dispatch(formActions.formLoaded({ job, steps }));
};

export const initApplication = (jobId, values) => async (dispatch) => {
  const [err, data] = await to(
    candidateService.create(jobId, { name: values.name, email: values.email }),
  );

  if (err || !data?.id) {
    return;
  }

  const { id } = data;

  dispatch(formActions.formApplicationCreated(id));

  if (id && values.resume) {
    const dataImage = new FormData();
    dataImage.append('file', values.resume);

    await to(candidateService.uploadResume(dataImage, id));
  }
};

export const uploadVideo = (applicationId, param, video, isFinal) => async (dispatch) => {
  dispatch(formActions.formUploading({ param }));

  const initId = await dispatch(videoThunks.init(param));
  const url = await dispatch(videoThunks.uploadVideo(initId, video, param));
  const body = { [param]: url };

  if (isFinal) {
    body.finished = true;
  }

  await dispatch(formActions.formUploaded({ param, url }));
  await dispatch(candidateThunks.update(applicationId, body));
};

export const thunks = {
  init,
  initApplication,
  uploadVideo,
};
