import * as apiService from '../utils/apiService';
import {
  PROFILE_URL,
  PROFILE_UPDATE_URL,
  PROFILE_LOGO_URL,
  PROFILE_REG_URL,
  PROFILE_RECOVERY_URL,
  PROFILE_RESET_URL,
} from './constants';

export class ProfileService {
  async get() {
    const { data } = await apiService.axiosPrivate.get(PROFILE_URL);
    return data;
  }

  async update(body) {
    const { data } = await apiService.axiosPrivate.patch(
      PROFILE_UPDATE_URL,
      body,
    );
    return data;
  }

  async recovery(body) {
    const { data } = await apiService.axiosPublic.post(
      PROFILE_RECOVERY_URL,
      body,
    );
    return data;
  }

  async updateLogo(body) {
    const { data } = await apiService.axiosPrivate.patch(
      PROFILE_LOGO_URL,
      body,
    );
    return data;
  }

  async register(body) {
    const { data } = await apiService.axiosPublic.post(PROFILE_REG_URL, body);
    return data;
  }

  async newPassword(body, id) {
    const { data } = await apiService.axiosPublic.post(
      PROFILE_RESET_URL,
      body,
      id,
    );
    return data;
  }
}
export const profileService = new ProfileService();
