import React from 'react';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/material';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import message from './img/message.svg';
import CButton from '../../components/СButton';
import CInput from '../../components/CInput';
import { ValidationSchema } from './EmailValidationSchemas';
import { thunksProfile } from '../../store/profile/profile.thunks';

function ResetPassword() {
  const dispatch = useDispatch();
  return (
    <Box>
      <Box
        sx={{
          position: 'absolute',
          opacity: '0.6',
          zIndex: 10,
          top: '-47px',
          left: 0,
        }}
      />
      <Formik
        initialValues={{ email: '' }}
        validationSchema={ValidationSchema}
        onSubmit={(values) => {
          dispatch(thunksProfile.recovery(values));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <CInput
              placeholder="Email"
              sx={{ mb: '12px' }}
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              InputProps={{
                startAdornment: (
                  <IconButton sx={{ left: '8px', pr: '20px' }}>
                    <img src={message} alt="message" />
                  </IconButton>
                ),
              }}
            />
            {errors.email && touched.email && errors.email}
            <CButton type="submit" disabled={isSubmitting} height="48px">
              Continue
            </CButton>
          </form>
        )}
      </Formik>
    </Box>
  );
}

export default ResetPassword;
