import {
  Box, Container, Grid, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Done from '@mui/icons-material/Done';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Footer from '../../components/Footer';
import VideoInput from './VideoInput';
import { thunks } from '../../store/job/job.thunks';
import { thunks as videoThunk } from '../../store/video/video.thunks';
import TitleDesktop from '../../components/TitleDesktop';
import { jobsSelector } from '../../store/job/job.selectors';
import CButton from '../../components/СButton';
import CustomFontFamily from '../../components/TextStyle/index.style';
import Progress from '../Form/component/Progress';
import { video } from '../../store/video/video.selectors';
import QuestionVideo from '../../components/FormContainer/component/QuestionVideo';

function UpdateJob() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const job = useSelector(jobsSelector.job);
  const [updates, setUpdates] = useState({});
  const [isOpen, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    dispatch(thunks.getJob(id));
  }, [dispatch, id]);

  const handleChange = (param, value) => {
    setUpdates({ ...updates, [param]: value });
  };

  const handleSave = async (videoParam, descriptionParam) => {
    const body = {};

    if (updates[videoParam]) {
      const videoId = await dispatch(videoThunk.init(videoParam));

      body[videoParam] = await dispatch(
        videoThunk.uploadVideo(videoId, updates[videoParam], videoParam),
      );
    }

    if (updates[descriptionParam]) {
      body[descriptionParam] = updates[descriptionParam];
    }

    await dispatch(thunks.update(id, body));
    dispatch(thunks.getJob(id));

    delete updates[videoParam];
    delete updates[descriptionParam];
  };

  const handleReset = async (videoType, descriptionType) => {
    await dispatch(thunks.reset(id, { videoType, descriptionType }));
    dispatch(thunks.getJob(id));
  };

  const handleStateChange = async (param, value) => {
    await dispatch(thunks.update(id, { [param]: value }));
    dispatch(thunks.getJob(id));
  };

  const videoData = useSelector(video);
  const isProgress = !!Object.keys(videoData).length;
  const count = Object.values(videoData).reduce(
    (acc, { count }) => acc + count,
    0,
  );
  const total = Object.values(videoData).reduce(
    (acc, { total }) => acc + total,
    0,
  );

  const percent = count && total ? parseInt((count / total) * 100) : 0;

  return (
    <>
      {isOpen ? (<QuestionVideo open={isOpen} handleClose={() => setOpen(false)} />) : (<> </>)}
      <Box>
        <Progress value={percent} show={isProgress} />
      </Box>
      <Container>

        <Box
          sx={{
            pt: '20px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <TitleDesktop title={job.title}>
            <Box sx={{ px: '5px' }}>
              <CButton onClick={() => handleClickOpen()}>
                <HelpOutlineIcon sx={{ fontSize: '22px' }} color="#fff" />
              </CButton>
            </Box>
            <Box sx={{ px: '5px' }}>
              <CButton onClick={() => navigate(`/applications/${id}`)}>
                Complete
              </CButton>
            </Box>

          </TitleDesktop>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mb: '15px',
            }}
          >
            <CustomFontFamily
              sx={{
                fontWeight: '700',
                fontSize: { xs: '16px', md: '24px' },
                color: '#000000',
              }}
            >
              Customize Video Interview
            </CustomFontFamily>
            <CustomFontFamily
              sx={{
                fontWeight: '400',
                fontSize: { xs: '14px', md: '24px' },
                color: '#989A9E',
              }}
            >
              Record or Upload Videos Below
            </CustomFontFamily>
            <CustomFontFamily
              sx={{
                fontWeight: '400',
                fontSize: { xs: '14px', md: '24px' },
                color: '#989A9E',
              }}
            >
              **Don&apos;t forget to Save and Complete
            </CustomFontFamily>
          </Box>
          <Box sx={{ maxWidth: '1180px', pb: '80px' }}>
            <Grid container>
              <VideoInput
                param="introductionVideo"
                label="Introduction Video"
                onChange={handleChange}
                onSave={handleSave}
                initialVideo={job.introductionVideo}
                desc={
                  updates.introductionDescription || job.introductionDescription
                }
                enabled={job.introductionEnabled}
                enabledParam="introductionEnabled"
                onStatusChange={handleStateChange}
                name="introductionDescription"
                onReset={handleReset}
              />

              <VideoInput
                param="explainerVideo"
                label="Explainer Video"
                onChange={handleChange}
                onSave={handleSave}
                initialVideo={job.explainerVideo}
                desc={updates.explainerDescription || job.explainerDescription}
                name="explainerDescription"
                onReset={handleReset}
                enabled={job.explainerEnabled}
                enabledParam="explainerEnabled"
                onStatusChange={handleStateChange}
              />

              <VideoInput
                param="firstQuestion"
                label="Question #1"
                onChange={handleChange}
                onSave={handleSave}
                initialVideo={job.firstQuestion}
                desc={updates.firstDescription || job.firstDescription}
                name="firstDescription"
                onReset={handleReset}
                enabled={job.firstEnabled}
                enabledParam="firstEnabled"
                onStatusChange={handleStateChange}
              />

              <VideoInput
                param="secondQuestion"
                label="Question #2"
                onChange={handleChange}
                onSave={handleSave}
                initialVideo={job.secondQuestion}
                desc={updates.secondDescription || job.secondDescription}
                name="secondDescription"
                onReset={handleReset}
                enabled={job.secondEnabled}
                enabledParam="secondEnabled"
                onStatusChange={handleStateChange}
              />

              <VideoInput
                param="thirdQuestion"
                label="Question #3"
                onChange={handleChange}
                onSave={handleSave}
                initialVideo={job.thirdQuestion}
                desc={updates.thirdDescription || job.thirdDescription}
                name="thirdDescription"
                onReset={handleReset}
                enabled={job.thirdEnabled}
                enabledParam="thirdEnabled"
                onStatusChange={handleStateChange}
              />
              <VideoInput
                param="fourthQuestion"
                label="Question #4"
                onChange={handleChange}
                onSave={handleSave}
                initialVideo={job.fourthQuestion}
                desc={updates.fourthDescription || job.fourthDescription}
                name="fourthDescription"
                onReset={handleReset}
                enabled={job.fourthEnabled}
                enabledParam="fourthEnabled"
                onStatusChange={handleStateChange}
              />
            </Grid>
            <Box>
              <Footer>
                <Typography>New Job</Typography>
                <Done onClick={() => navigate(`/applications/${id}`)} />
              </Footer>
            </Box>
          </Box>
        </Box>
      </Container>
    </>

  );
}

export default UpdateJob;
