import { styled } from '@mui/material';
import { SECONDARY_COLOR } from '../../config/config';
import CustomFontFamily from '../TextStyle/index.style';

export const CustomTitleDesk = styled(CustomFontFamily)({
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '22px',
  color: SECONDARY_COLOR,
});

export const CustomText = styled(CustomFontFamily)({
  display: 'inline-block',
  fontWeight: '600',
  fontSize: '12px',
  lineHeight: '22px',
  textAlign: 'center',
  textTransform: 'uppercase',
});
