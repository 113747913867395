import { Box, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import { CustomHeadTitle, CustomOptionText } from '../../../Candidates/index.style';

function Header({
  data, onApprove, onDecline, onDelete,
}) {
  return (
    <Box
      sx={{
        display: { xs: 'none', md: 'flex' },
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        pb: '48px',
      }}
    >
      <Box sx={{
        width: '100%',
        pr: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'flex-end',
        height: '40px',
      }}
      >
        <CustomHeadTitle>{data.name}</CustomHeadTitle>
        <Divider sx={{ width: '95%' }} />
      </Box>
      <Box onClick={onApprove}>
        <Box
          sx={{
            mx: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: '#54BA8F',
            borderRadius: '50%',
            width: '50px',
            height: '50px',
            cursor: 'pointer',
          }}
        >
          <AddIcon sx={{ color: '#FFF' }} />
        </Box>
        <CustomOptionText sx={{ color: '#54BA8F', fontSize: '15px' }}> Invite</CustomOptionText>
      </Box>
      <Box onClick={onDecline}>
        <Box
          sx={{
            mx: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: '#FFA800',
            borderRadius: '50%',
            width: '50px',
            height: '50px',
            cursor: 'pointer',
          }}
        >
          <ClearIcon sx={{ color: '#FFF' }} />
        </Box>
        <CustomOptionText sx={{ color: '#FFA800', fontSize: '15px' }}>Decline</CustomOptionText>
      </Box>

      <Box onClick={onDelete}>
        <Box
          sx={{
            mx: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: '#EB5050',
            borderRadius: '50%',
            width: '50px',
            height: '50px',
            cursor: 'pointer',
          }}
        >
          <DeleteIcon sx={{ color: '#FFF' }} />
        </Box>
        <CustomOptionText sx={{ color: '#EB5050', fontSize: '15px' }}> Delete</CustomOptionText>
      </Box>
    </Box>
  );
}

export default Header;
