import to from 'await-to-js';
import { candidateService } from '../../services/candidates/candidateApiService';
import { actions as candidateActions } from './candidates.actions';

const getAll = (id) => async (dispatch) => {
  dispatch(candidateActions.getAllCandidateInitialized(id));

  const [err, data] = await to(candidateService.getAll(id));
  if (err) {
    return;
  }
  dispatch(candidateActions.getAllCandidateSuccessful(data));
};

const get = (id) => async (dispatch) => {
  dispatch(candidateActions.getCandidateInitialized(id));

  const [err, data] = await to(candidateService.get(id));
  if (err) {
    return;
  }
  dispatch(candidateActions.getCandidateSuccessful(data));
};

const approve = (id) => async (dispatch) => {
  dispatch(candidateActions.approveCandidateInitialized(id));

  const [err, data] = await to(candidateService.approve(id));
  if (err) {
    return;
  }
  dispatch(candidateActions.approveCandidateSuccessful(data));
};

const decline = (id) => async (dispatch) => {
  dispatch(candidateActions.declineCandidateInitialized(id));

  const [err, data] = await to(candidateService.decline(id));
  if (err) {
    return;
  }
  dispatch(candidateActions.declineCandidateSuccessful(data));
};

const remove = (id) => async (dispatch) => {
  dispatch(candidateActions.deleteCandidateInitialized(id));

  const [err, data] = await to(candidateService.delete(id));
  if (err) {
    return;
  }
  if (data) {
    dispatch(candidateActions.deleteCandidateSuccessful(id));
  }
};

const update = (id, body) => async (dispatch) => {
  dispatch(candidateActions.updateCandidateInitialized());

  const [err, data] = await to(candidateService.update(id, body));
  if (err) {
    return;
  }
  dispatch(candidateActions.updateCandidateSuccessful(data));
};

const updateRating = (body, id) => async (dispatch) => {
  dispatch(candidateActions.updateRatingCandidateInitialized(id, body));
  const [err] = await to(candidateService.updateRating(id, body));
  if (err) {
    return;
  }

  dispatch(
    candidateActions.updateRatingCandidateSuccessful({ rating: body.value }),
  );
};

const updateNote = (id, body) => async (dispatch) => {
  dispatch(candidateActions.updateNoteInitialized());

  const [err] = await to(candidateService.update(id, body));
  if (err) {
    return;
  }
  dispatch(candidateActions.updateNoteSuccessful({ note: body.value }));
};

export const thunks = {
  getAll,
  get,
  approve,
  decline,
  remove,
  update,
  updateNote,
  updateRating,
};
