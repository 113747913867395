import CInput from '../../../../components/CInput';

function InputItem({ formik }) {
  return (
    <>
      <CInput
        sx={{ mb: '15px', maxWidth: '780px' }}
        name="name"
        onChange={formik.handleChange}
        value={formik.values.name}
        placeholder="Name"
      />
      <CInput
        sx={{ mb: '15px', maxWidth: '780px' }}
        name="companyName"
        onChange={formik.handleChange}
        value={formik.values.companyName}
        placeholder="Company name"
      />
      <CInput
        sx={{ maxWidth: '780px' }}
        name="link"
        onChange={formik.handleChange}
        value={formik.values.link}
        placeholder="Calendly link"
      />
    </>
  );
}

export default InputItem;
