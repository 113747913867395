import React from 'react';
import { Box } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CustomTitleLogo from '../../../HeaderMobile/index.style';
import logo from './img/logo.png';
import { MAIN_COLOR } from '../../../../config/config';

function Header({ cLogo, onClickOpen }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        background: '#FFFFFF',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        height: '59px',
        py: '5px',
      }}
    >
      <img src={cLogo || logo} alt="logo" style={{ maxHeight: '35px' }} />
      <CustomTitleLogo>powered by intervuuio</CustomTitleLogo>
      <Box sx={{ position: 'absolute', top: '2%', right: '10%' }}>
        <HelpOutlineIcon sx={{ color: MAIN_COLOR }} onClick={onClickOpen} />
      </Box>
    </Box>
  );
}

export default Header;
