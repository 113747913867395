import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { authReducer } from './auth/auth.reducer';
import { baseReducer } from './base/base.reducer';
import { videoReducer } from './video/video.reducer';
import { jobReducer } from './job/job.reducer';
import { candidatesReducer } from './candidates/candidates.reducer';
import { profileReducer } from './profile/profile.reducer';
import { formReducer } from './form/form.reducer';

const authPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['access_token', 'refresh_token', 'expires_at'],
};

const reducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  base: baseReducer,
  uploads: videoReducer,
  job: jobReducer,
  candidates: candidatesReducer,
  profile: profileReducer,
  form: formReducer,
});

export default reducer;
