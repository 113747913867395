import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ShareIcon from '@mui/icons-material/Share';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Box, Button, Pagination, Typography,
} from '@mui/material';
import { thunks as candidateThunks } from '../../store/candidates/candidates.thunks';
import { candidateSelectors } from '../../store/candidates/candidates.selectors';
import Footer from '../../components/Footer';
import usePagination from '../../components/Paginator';
import CandidateItem from './component/CandidateItem';
import Modal from '../../components/Modal';
import { profileSelectors } from '../../store/profile/profile.selectors';
import CButton from '../../components/СButton';
import { thunks, thunks as jobThunks } from '../../store/job/job.thunks';
import { CustomTextButton } from '../Jobs/index.style';
import TableHead from './component/TableHead';
import TitleDesktop from '../../components/TitleDesktop';
import { useConfirmation } from '../../hook/confirm';

const PER_PAGE = 12;

function Candidates() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector(candidateSelectors.applications);
  const profile = useSelector(profileSelectors.profile);
  const [title, setTitle] = useState();
  const [selectedLink, setSelectedLink] = useState(null);
  const [page, setPage] = useState(1);
  const [state, setState] = useState(false);
  const { modal, show } = useConfirmation(async (data, candidateId) => {
    if (data.type === 'invite') {
      await dispatch(candidateThunks.approve(candidateId));
      await dispatch(candidateThunks.getAll(id));
    }
    if (data.type === 'decline') {
      await dispatch(candidateThunks.decline(candidateId));
      await dispatch(candidateThunks.getAll(id));
    }
    if (data.type === 'delete') {
      await dispatch(candidateThunks.remove(candidateId));
      await dispatch(candidateThunks.getAll(id));
    }

    if (data.type === 'deleteJob') {
      await dispatch(thunks.deleteJob(id));
      navigate('/app/jobs');
    }
  });

  const count = Math.ceil(data.length / PER_PAGE);
  const pagination = usePagination(data, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    pagination.jump(p);
  };
  const linkHandle = `${window.location.origin}/form/${id}`;

  useEffect(() => {
    dispatch(candidateThunks.getAll(id));
    setSelectedLink(linkHandle);
    (async () => {
      const title = await dispatch(jobThunks.getJob(id));
      return setTitle(title);
    })();
  }, [dispatch, id]);

  return (
    <Box sx={{
      pt: { xs: '10px', md: '100px' },
      pb: { xs: '85px', md: '10px' },
      px: { xs: '30px', md: '108px' },
    }}
    >
      {state ? (
        <Modal
          link={selectedLink}
          jobTitle={title}
          company={profile.companyName}
          onClose={() => setState(false)}
        />
      ) : (
        <></>
      )}
      {modal}
      <Box>
        <TitleDesktop title={title}>
          <Box sx={{ pr: 2, minWidth: '125px' }}>
            <CButton height="40px" onClick={() => show({ type: 'deleteJob' }, 'delete job')}>
              <CustomTextButton>Delete</CustomTextButton>
            </CButton>
          </Box>
          <Box sx={{ pr: 2, minWidth: '125px' }}>
            <a href={`${selectedLink}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
              <CButton height="40px">
                <CustomTextButton>Preview</CustomTextButton>
              </CButton>
            </a>
          </Box>
          <Box sx={{ pr: 2, minWidth: '125px' }}>
            <CButton height="40px" onClick={() => navigate(`/app/updatejob/${id}`)}>
              <CustomTextButton>Edit</CustomTextButton>
            </CButton>
          </Box>
          <Box sx={{ pr: 2, minWidth: '125px' }}>
            <CButton height="40px" onClick={() => setState(true)}>
              <CustomTextButton>Share</CustomTextButton>
            </CButton>
          </Box>
        </TitleDesktop>

        {
          data.length
            ? <TableHead />
            : <p>You have 0 Video Resume Submissions, Please Share your link to Applicants</p>
        }

        {pagination.currentData().map((candidate) => (
          <CandidateItem
            candidate={candidate}
            key={candidate.id}
            idJob={id}
            onInvite={() => show({ type: 'invite' }, 'invite', candidate.id)}
            onDecline={() => show({ type: 'decline' }, 'decline', candidate.id)}
            onDelete={() => show({ type: 'delete' }, 'delete', candidate.id)}
          />
        ))}

        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          {
            data.length ? (
              <Pagination
                count={count}
                size="large"
                page={page}
                variant="outlined"
                onChange={handleChange}
              />
            ) : <></>
          }

        </Box>
      </Box>

      <Footer>
        <Button onClick={() => navigate(`/app/updatejob/${id}`)} sx={{ color: '#fff' }}>
          <SettingsIcon />
        </Button>
        <Typography> Test Job</Typography>
        <Button onClick={() => setState(true)} sx={{ color: '#fff' }}>
          <ShareIcon />
        </Button>
      </Footer>
    </Box>
  );
}

export default Candidates;
