import React from 'react';
import profile from './img/profile.svg';

function ProfileIcon({ active, width, height }) {
  return (
    <>
      {active ? (
        <img
          src={profile}
          style={{
            width,
            height,
            // TODO: Check SVG
            filter:
              'invert(55%) sepia(61%) saturate(5289%) hue-rotate(334deg) brightness(102%) contrast(84%)',
          }}
          alt="profileActive"
        />
      ) : (
        <img
          src={profile}
          style={{ width, height }}
          alt="profile"
        />
      )}
    </>
  );
}

export default ProfileIcon;
