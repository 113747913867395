import { Box } from '@mui/material';

function Viewer({ src, disableAutoplay }) {
  return (
    <Box>
      <video
        src={src}
        autoPlay={!disableAutoplay}
        controls
        playsInline
        style={{
          position: 'absolute',
          padding: '0',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
    </Box>
  );
}

export default Viewer;
