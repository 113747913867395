import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Record from './component/Record';
import Intro from './component/Intro/index';
import Done from './component/Done';
import VideoExplainer from './component/VideoExplainer';
import { formSelector } from '../../store/form/form.selectors';
import { init } from '../../store/form/form.thunks';
import { actions as formActions } from '../../store/form/form.actions';
import Description from './component/Description';
import { video } from '../../store/video/video.selectors';
import Progress from './component/Progress';
import AlmostDone from './component/AlmostDone';
import { jobsSelector } from '../../store/job/job.selectors';
import { JobTitleInForm } from './component/index.style';

function Form() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const form = useSelector(formSelector);
  const [direction, setDirection] = useState(1);
  useSelector(jobsSelector.job);

  useEffect(() => {
    dispatch(init(id));
  }, [dispatch, id]);

  const stepObj = form.steps[form.activeStep];

  const handleBack = () => {
    setDirection(-1);
    dispatch(formActions.formPrevStep());
  };

  const handleNext = () => {
    setDirection(1);
    dispatch(formActions.formNextStep());
  };

  let step;

  switch (stepObj?.type) {
    case 'intro':
      step = <Intro id={id} video={stepObj.video} onFinish={handleNext} />;
      break;

    case 'explainer':
      step = (
        <VideoExplainer id={id} video={stepObj.video} onFinish={handleNext} />
      );
      break;
    case 'record':
      step = (
        <Record
          key={stepObj.param}
          id={id}
          param={stepObj.param}
          questionVideo={stepObj.video}
          onBack={handleBack}
          onFinish={handleNext}
          direction={direction}
          isFinal={form.steps.length - form.activeStep === 3}
        />
      );
      break;
    case 'almost-done':
      step = <AlmostDone onBack={handleBack} onFinish={handleNext} />;
      break;
    case 'done':
      step = <Done />;
      break;
    default:
      break;
  }

  const videoData = useSelector(video);
  const isProgress = !!Object.keys(videoData).length;
  const count = Object.values(videoData).reduce(
    (acc, { count }) => acc + count,
    0,
  );
  const total = Object.values(videoData).reduce(
    (acc, { total }) => acc + total,
    0,
  );
  const percent = count && total ? parseInt((count / total) * 100) : 0;

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box>
        <Progress value={percent} show={isProgress} />
      </Box>
      <Box
        sx={{
          // display: 'flex',
          // flexDirection: 'column',
          height: '100%',
        }}
      >
        <JobTitleInForm sx={{ textAlign: 'center', fontSize: { xs: '15px', md: '25px' } }}>{form?.job?.title}</JobTitleInForm>
        {stepObj?.type === 'done' || stepObj?.type === 'almost-done' ? (
          <></>
        ) : (
          <Description
            activeStep={form.activeStep}
            text={stepObj}
          />
        )}

        {step}
      </Box>
    </Box>
  );
}

export default Form;
