import { useState } from 'react';
import Confirm from '../../components/Confirm';

export const useConfirmation = (onConfirm) => {
  const [state, setState] = useState({
    open: false, data: null, text: '', id: null,
  });

  return {
    modal: state.open
      ? (
        <Confirm
          open={state.open}
          actionText={state.text}
          action={() => {
            setState({
              open: false, data: null, text: '', id: state.id,
            });
            onConfirm(state.data, state.id);
          }}
          handleClose={() => setState({
            open: false, data: null, text: '', id: null,
          })}
        />
      ) : <></>,
    show: (data, text, id) => {
      setState({
        open: true, data, text, id,
      });
    },
  };
};
