import { Box, Divider } from '@mui/material';
import React from 'react';
import { CustomHeadTitle } from '../../scenes/Candidates/index.style';

function TitleDesktop({ title, children }) {
  return (
    <Box
      sx={{
        display: { xs: 'none', md: 'flex' },
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        pb: '48px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: '100%',
          pr: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          justifyContent: 'flex-end',
          height: '52px',
        }}
      >
        <CustomHeadTitle>{title}</CustomHeadTitle>
        <Divider sx={{ width: '100%' }} />
      </Box>
      <Box sx={{ display: 'flex' }}>
        {children}
      </Box>
    </Box>
  );
}

export default TitleDesktop;
