function UploadIcon({ width = 22, height = 22 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0.634277C9.18534 0.639968 7.43254 1.29446 6.05825 2.47953C5.005 3.38703 4.24188 4.56953 4.04525 5.75615C1.74075 6.2649 0 8.2724 0 10.6965C0 13.5125 2.3485 15.7593 5.19887 15.7593H10.3125V8.4814L7.36175 11.4335C7.23266 11.5626 7.05757 11.6351 6.875 11.6351C6.69243 11.6351 6.51734 11.5626 6.38825 11.4335C6.25916 11.3044 6.18663 11.1293 6.18663 10.9468C6.18663 10.7642 6.25916 10.5891 6.38825 10.46L10.5132 6.33503C10.5771 6.271 10.653 6.22021 10.7365 6.18555C10.82 6.15089 10.9096 6.13305 11 6.13305C11.0904 6.13305 11.18 6.15089 11.2635 6.18555C11.347 6.22021 11.4229 6.271 11.4868 6.33503L15.6117 10.46C15.7408 10.5891 15.8134 10.7642 15.8134 10.9468C15.8134 11.1293 15.7408 11.3044 15.6117 11.4335C15.4827 11.5626 15.3076 11.6351 15.125 11.6351C14.9424 11.6351 14.7673 11.5626 14.6383 11.4335L11.6875 8.4814V15.7593H17.446C19.9403 15.7593 22 13.793 22 11.3222C22 9.07265 20.2922 7.23978 18.1033 6.9304C17.7691 3.3829 14.6988 0.634277 11 0.634277ZM10.3125 20.5718V15.7593H11.6875V20.5718C11.6875 20.7541 11.6151 20.929 11.4861 21.0579C11.3572 21.1868 11.1823 21.2593 11 21.2593C10.8177 21.2593 10.6428 21.1868 10.5139 21.0579C10.3849 20.929 10.3125 20.7541 10.3125 20.5718Z"
        fill="#CBCDD0"
      />
    </svg>
  );
}

export default UploadIcon;
