import { styled } from '@mui/material';
import CustomFontFamily from '../../components/TextStyle/index.style';

export const CustomProfileButton = styled(CustomFontFamily)({
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '20px',
  fontHeight: '26px',
  color: '#989A9E',
});
