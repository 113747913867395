import { styled } from '@mui/material';
import CustomFontFamily from '../TextStyle/index.style';

export const CustomTextModal = styled(CustomFontFamily)({
  fontWeight: 600,
  fontSize: '11px',
  lineHeight: '17px',
  color: '#000000',
  textTransform: 'none',
});

export const CustomTextModalBlock = styled(CustomFontFamily)({
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '18px',
  // identical to box height, or 220%
  color: '#989A9E',
});
