import React from 'react';
import { CustomTypographyCandidate, CustomTypographyTitle } from '../../../../index.style';

function ItemDescription({ label, value }) {
  return (
    <>
      <CustomTypographyTitle pb="10px">
        {label}
      </CustomTypographyTitle>
      <CustomTypographyCandidate pb="10px">
        {value}
      </CustomTypographyCandidate>
    </>
  );
}

export default ItemDescription;
