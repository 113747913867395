import React from 'react';
import { Link } from '@mui/material';
import { CustomTypographyTitle } from '../../../../index.style';
import { CustomTitleForJob } from '../../../../../../components/TextStyle/component/index.style';

function DescItemEmail({ label, value }) {
  return (
    <>
      <CustomTypographyTitle pb="10px" sx={{ fontSize: { xs: '18px', md: '22px' } }}>
        {label}
      </CustomTypographyTitle>
      <Link href={`mailto:${value}`}>
        <CustomTitleForJob sx={{
          mb: '10px',
          fontSize: { xs: '18px', md: '20px' },
          lineHeight: { xs: '18px', md: '24px' },
        }}
        >
          {value}
        </CustomTitleForJob>
      </Link>
    </>
  );
}
export default DescItemEmail;
