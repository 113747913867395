import { Box } from '@mui/material';
import upload from '../../img/upload.svg';
import { CustomProfileButton } from '../../index.style';

function ImageItem({
  image, onChange, style, logo,
}) {
  return (
    <>
      <Box
        sx={{
          width: { xs: '100px', md: '200px' },
          height: { xs: '100px', md: '200px' },
          backgroundColor: '#f5f5f5',
          mt: 1,
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {image || logo ? (
          <img src={image || logo} style={{ width: '100%' }} alt="logo" />
        ) : (
          <Box
            sx={{
              color: '#d1d4d7',
              fontSize: '3rem',
              width: '100%',
              height: '100%',
            }}
            alt="logo"
          />
        )}
      </Box>
      <Box sx={{ pt: '10px', pb: '30px' }}>
        <label
          htmlFor="contained-button-file"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <input
            id="contained-button-file"
            type="file"
            name="logo"
            onChange={onChange}
            style={{ display: 'none' }}
          />
          <Box sx={{ width: '19px', height: '19px', mr: '15px' }}>
            <img src={upload} alt="upload" />
          </Box>
          <CustomProfileButton sx={style}>Upload</CustomProfileButton>
        </label>
      </Box>
    </>
  );
}

export default ImageItem;
