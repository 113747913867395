import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import About from '../About';
import { ValidationSchema } from '../About/EmailValidationSchemas';
import { thunks } from '../../../../store/form/form.thunks';
import ContentBox from '../ContentBox';
import VideoBox from '../../../../components/VideoBox';
import VideoViewer from '../../../../components/VideoViewer';
import Bottom from './component/Bottom';

function Intro({ id, video, onFinish }) {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();

  const handlePrev = () => setStep(0);

  const handleNext = (submitForm) => {
    if (isMd || step === 1) {
      submitForm();
    } else {
      setStep(1);
    }
  };

  return (
    <Box
      sx={{
        px: '34px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Formik
        initialValues={{ name: '', email: '', resume: null }}
        validationSchema={ValidationSchema}
        onSubmit={async (values) => {
          await dispatch(thunks.initApplication(id, values));

          onFinish();
        }}
      >
        {({ submitForm, ...formik }) => (
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              maxWidth: '99%',
            }}
          >
            <ContentBox>
              {isMd || step === 0 ? (
                <Box sx={{ mr: { md: '20px' } }}>
                  <VideoBox>
                    <VideoViewer src={video} />
                  </VideoBox>
                </Box>
              ) : (
                <></>
              )}

              {isMd || step === 1 ? <About {...formik} /> : <></>}
            </ContentBox>

            <Bottom
              step={step}
              onPrev={handlePrev}
              onNext={() => handleNext(submitForm)}
            />
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default Intro;
