import * as apiService from '../utils/apiService';
import { LOGIN_URL, REFRESH_URL, LOGOUT_URL } from './constants';

export class AuthService {
  async login(body) {
    const { data } = await apiService.axiosPublic.post(LOGIN_URL, body);

    return data;
  }

  async logout() {
    const { data } = await apiService.axiosPrivate.get(LOGOUT_URL);

    return data;
  }

  async refresh(body) {
    const { data } = await apiService.axiosPublic.post(REFRESH_URL, body);

    return data;
  }
}
export const authService = new AuthService();
