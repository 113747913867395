import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box, LinearProgress, linearProgressClasses } from '@mui/material';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: '#FF5656',
  },
}));

function Progress({ value, show }) {
  return (
    <Box sx={{ width: '100%', visibility: show ? 'visible' : 'hidden' }}>
      <BorderLinearProgress variant="determinate" value={value} />
    </Box>
  );
}

export default Progress;
