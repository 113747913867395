import { Button, Box } from '@mui/material';
import { SECONDARY_COLOR } from '../../../../config/config';
import UploadIcon from '../../../UploadIcon';

function UploadButton({ onChange }) {
  return (
    <label>
      <input
        type="file"
        accept="video/*"
        style={{ display: 'none' }}
        onChange={onChange}
      />
      <Button
        component="span"
        sx={{
          fontWeight: 700,
          fontSize: '10px',
          color: SECONDARY_COLOR,
          background: 'rgba(80, 81, 84, 0.8)',
          '&:hover': {
            background: 'rgba(80, 81, 84, 0.8)',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mr: '5px',
          }}
        >
          <UploadIcon width={16} height={16} />
        </Box>
        Upload
      </Button>
    </label>
  );
}

export default UploadButton;
