import { Box, Button } from '@mui/material';
// import upload from '../../img/upload.png';
import UploadIcon from './component/UploadIcon';
import UploadedIcon from './component/UploadedIcon';

function Upload({ onChange, value }) {
  return (
    <label htmlFor="upload-photo">
      <input
        style={{ display: 'none' }}
        id="upload-photo"
        name="resume"
        type="file"
        accept="application/pdf"
        onChange={onChange}
      />
      <Button
        sx={{
          px: '14px',
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          background: '#FFFFFF',
          boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
          borderRadius: '5px',
          height: '50px',
          zIndex: 10,
          border: value ? '1px solid #54BA8F' : '',
        }}
        component="span"
        fullWidth
      >
        <Box
          sx={{
            fontStyle: 'normal',
            fontSize: '14px',
            fontFamily: 'Poppins',
            fontWeight: '600',
            color: '#CBCDD0',
          }}
        >
          Upload Resume
        </Box>
        <Box
          sx={{
            width: '18px',
            height: '17px',
          }}
        >
          {value ? <UploadedIcon /> : <UploadIcon />}
        </Box>
      </Button>
    </label>
  );
}

export default Upload;
