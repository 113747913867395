import CustomFontFamily from '../../../../../../components/TextStyle/index.style';

function Finish() {
  return (
    <CustomFontFamily
      sx={{
        fontWeight: '700',
        fontSize: { xs: '16px', md: '24px' },
        color: '#000000',
      }}
    >
      Please wait till all video uploaded
    </CustomFontFamily>
  );
}

export default Finish;
