import { styled } from '@mui/material';
import CustomFontFamily from '../index.style';

export const CustomTitleForJob = styled(CustomFontFamily)({
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '20px',
  color: '#000',
  textAlign: 'start',
});

export const CustomDescriptionForJob = styled(CustomFontFamily)({
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '18px',
  color: '#989A9E',
  textAlign: 'start',
});

export const CustomFooterText = styled(CustomFontFamily)({
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '22px',
  color: '#FFFFFF',
});

export const CustomCandidateText = styled(CustomFontFamily)({
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '22px',
  color: '#989A9E',
});
