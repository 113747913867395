import { Box } from '@mui/material';

function ContentBox({ children, single }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: single ? 'center' : 'space-between',
        alignItems: 'center',
        maxWidth: '100%',
        background: { xs: 'none', md: '#FFFFFF' },
        boxShadow: { xs: 'none', md: '0px 2px 10px rgba(0, 0, 0, 0.1)' },
        borderRadius: '10px',
        p: { xs: 0, md: '15px', lg: '20px' },
        // px: { xs: 0, md: '15px', lg: '50px' },
        mt: { xs: '20px', md: '0' },
      }}
    >
      {children}
    </Box>
  );
}

export default ContentBox;
