import { actions as profileActions } from './profile.actions';

const initialState = {
  profile: {},
  logo: null,
  update: null,
  forgotPasswordId: null,

};

export const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case profileActions.getProfileInitialized.type:
      return state;
    case profileActions.getProfileSuccessful.type:
      return {
        ...state,
        profile: action.payload,
      };

    case profileActions.updateProfileInitialized.type:
      return state;
    case profileActions.updateProfileSuccessful.type:
      return {
        ...state,
        update: action.payload,
      };
    case profileActions.updateProfileLogoInitialized.type:
      return state;
    case profileActions.updateProfileLogoSuccessful.type:
      return {
        ...state,
        logo: action.payload.logo,
      };
    case profileActions.regInitialized.type:
      return state;
    case profileActions.regSuccessful.type:
      return {
        ...state,
        name: action.payload.name,
        companyName: action.payload.companyName,
        email: action.payload.email,
        password: action.payload.password,
      };

    default:
      return state;
  }
};
