import { FormDescription, FormTitle } from '../index.style';

function Description({ activeStep, text }) {
  return (
    <>
      <FormTitle
        sx={{
          mt: '10px',
          fontSize: { md: '20px' },
          lineHeight: { md: '26px' },
        }}
      >
        Step
        {' '}
        {activeStep + 1}
      </FormTitle>
      <FormDescription
        sx={{
          mt: '10px',
          mx: 'auto',
          pb: { md: '50px' },
          fontSize: { md: '20px' },
          lineHeight: { md: '26px' },
          width: { md: '700px' },
        }}
      >
        {text?.description}
      </FormDescription>
    </>
  );
}

export default Description;
