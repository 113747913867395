import { actions as jobActions } from './job.actions';

const initialState = {
  jobs: [],
  jobInfo: {},
  job: {},
  createjob: {},
  update: null,
  reset: null,
};

export const jobReducer = (state = initialState, action) => {
  switch (action.type) {
    case jobActions.getAllJobInitialized.type:
      return state;
    case jobActions.getAllJobSuccessful.type:
      return {
        ...state,
        jobs: action.payload.Jobs,
      };
    case jobActions.getJobInfoInitialized.type:
      return state;
    case jobActions.getJobInfoSuccessful.type:
      return {
        ...state,
        jobInfo: action.payload,
      };
    case jobActions.getJobInitialized.type:
      return state;
    case jobActions.getJobSuccessful.type:
      return {
        ...state,
        job: action.payload,
      };
    case jobActions.createJobInitialized.type:
      return state;
    case jobActions.createJobSuccessful.type:
      return {
        ...state,
        createjob: action.payload.createjob,
      };
    case jobActions.updateJobInitialized.type:
      return state;
    case jobActions.updateJobSuccessful.type:
      return {
        ...state,
        update: action.payload.update,
      };

    case jobActions.resetJobInitialized.type:
      return state;
    case jobActions.resetJobSuccessful.type:
      return {
        ...state,
        reset: action.payload.reset,
      };
    case jobActions.deleteJobInitialized.type:
      return state;
    case jobActions.deleteJobSuccessful.type: {
      const data = [...state.jobs];
      const item = data.filter((x) => x.id !== action.payload);
      return { ...state, jobs: item };
    }

    default:
      return state;
  }
};
