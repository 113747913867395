import throat from 'throat';
import { actions as videoActions } from './video.actions';
import { videoService } from '../../services/video/videoApiService';

const init = (type) => async (dispatch) => {
  const video = { count: 0, total: 0, uploaded: false };
  dispatch(videoActions.initVideoId({ type, video }));

  return videoService.init();
};

const uploadVideo = (id, file, type, formVideo = true) => async (dispatch) => {
  dispatch(videoActions.startUploading());
  const size = 1 * 1024 * 1024;
  const fileChunks = [];

  let hashValue = 0;
  for (let current = 0; current < file.size; current += size) {
    fileChunks.push({
      hash: (hashValue += 1),
      chunk: file.slice(current, current + size),
    });
  }

  dispatch(videoActions.totalChunks({ type, total: fileChunks.length + 1 }));

  await Promise.all(
    fileChunks.map(
      throat(3, async (item) => {
        const formData = new FormData();
        formData.append('hash', item.hash);
        formData.append('chunk', item.chunk);

        const res = await videoService.upload(id, formData, formVideo);

        dispatch(videoActions.countChunks(type));

        return res;
      }),
    ),
  );
  dispatch(videoActions.finishUploading(type));

  const mergeRes = await videoService.mergeVideo(id, formVideo);

  dispatch(videoActions.deleteVideoData(type));

  return mergeRes;
};

export const thunks = {
  init,
  uploadVideo,
};
