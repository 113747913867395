import NavigateNext from '@mui/icons-material/NavigateNext';
import { Box, Grid } from '@mui/material';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Rating from '../../../../components/Rating';
import {
  CustomDescriptionForJob,
  CustomTitleForJob,
} from '../../../../components/TextStyle/component/index.style';
import ButtonItems from './component/ButtonItems';

function CandidateItem({
  candidate, onInvite, onDecline, onDelete,
}) {
  const navigate = useNavigate();
  const [status, statusDate] = useMemo(() => {
    if (candidate.invited) return ['Invited', candidate.invited];
    if (candidate.declined) return ['Declined', candidate.declined];

    return [];
  }, [candidate]);

  return (
    <Box
      sx={{
        px: '20px',
        py: '5px',
        background: '#FFFFFF',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '5px',
        mb: '15px',
      }}
    >
      <Grid container spacing={1} alignItems="center">
        <Grid item md={2} xs={8}>
          <Box
            onClick={() => navigate(`/app/candidates/${candidate.id}`)}
            sx={{ cursor: 'pointer' }}
          >
            <CustomTitleForJob>{candidate.name}</CustomTitleForJob>
            <CustomDescriptionForJob
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              {candidate.email}
            </CustomDescriptionForJob>
          </Box>
        </Grid>
        <Grid item md={2} sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Box>
            <CustomDescriptionForJob
              sx={{ display: { xs: 'none', md: 'block' } }}
            >
              {moment(parseInt(candidate.createdAt)).format(
                'MM/DD/YYYY h:mm A',
              )}
            </CustomDescriptionForJob>
          </Box>
        </Grid>
        <Grid item md={2} sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Box sx={{ overflow: 'hidden' }}>
            <CustomDescriptionForJob
              sx={{ display: { xs: 'none', md: 'block' } }}
            >
              {candidate.email}
            </CustomDescriptionForJob>
          </Box>
        </Grid>
        <Grid item md={2} xs={3}>
          <Rating rating={candidate.rating} />
        </Grid>
        <Grid item md={2} sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Box>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              {status ? (
                <Box>
                  <CustomDescriptionForJob>
                    {' '}
                    <b>
                      {status}
                      {' '}
                    </b>
                    {' '}
                    <br />
                    {' '}
                    {statusDate}
                  </CustomDescriptionForJob>
                </Box>
              ) : (
                <> </>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item md={2} xs={1}>
          <Box
            onClick={() => navigate(`/app/candidates/${candidate.id}`)}
            sx={{ display: { xs: 'flex', md: 'none' } }}
          >
            <NavigateNext sx={{ color: '#CBCDD0', fontSize: '35px' }} />
          </Box>
          <ButtonItems
            onClick={onInvite}
            onClick1={onDecline}
            onClick2={onDelete}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default CandidateItem;
