import to from 'await-to-js';
import { actions as profileActions } from './profile.actions';
import { profileService } from '../../services/profile/profileApiService';

const getProfile = (body) => async (dispatch) => {
  dispatch(profileActions.getProfileInitialized());
  const [err, data] = await to(profileService.get(body));
  if (err) {
    return;
  }
  dispatch(profileActions.getProfileSuccessful(data));
};

const updateProfile = (body) => async (dispatch) => {
  dispatch(profileActions.updateProfileInitialized());
  const [err, data] = await to(profileService.update(body));
  if (err) {
    return;
  }
  dispatch(profileActions.updateProfileSuccessful(data));
};

const register = (body) => async (dispatch) => {
  dispatch(profileActions.regInitialized());
  const [err, data] = await to(profileService.register(body));
  if (err) {
    return;
  }
  dispatch(profileActions.regSuccessful(data));
};

const updateProfileLogo = (body) => async (dispatch) => {
  dispatch(profileActions.updateProfileLogoInitialized());
  const [err, data] = await to(profileService.updateLogo(body));
  if (err) {
    return;
  }
  dispatch(profileActions.updateProfileLogoSuccessful(data));
};

const recovery = (body) => async (dispatch) => {
  dispatch(profileActions.recoveryInitialized());
  const [err, data] = await to(profileService.recovery(body));
  if (err) {
    return;
  }
  dispatch(profileActions.recoverySuccessful(data));
};

const newPassword = (body, id) => async (dispatch) => {
  dispatch(profileActions.newPasswordInitialized());
  const [err, data] = await to(profileService.newPassword(body, id));
  if (err) {
    return;
  }
  dispatch(profileActions.newPasswordSuccessful(data));
};

export const thunksProfile = {
  getProfile,
  updateProfile,
  updateProfileLogo,
  register,
  recovery,
  newPassword,
};
