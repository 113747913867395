import React from 'react';
import { Box, Button } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import CustomFontFamily from '../../../../components/TextStyle/index.style';
import Footer from '../../../../components/Footer';
import CButton from '../../../../components/СButton';

function VideoExplainer({ onBack, onFinish }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        pb: { xs: '76px', md: '' },
        height: '100%',
      }}
    >
      <CustomFontFamily
        sx={{
          fontWeight: '700',
          fontSize: { xs: '16px', md: '24px' },
          color: '#000000',
        }}
      >
        Almost done
      </CustomFontFamily>
      <CustomFontFamily
        sx={{
          fontWeight: '400',
          fontSize: { xs: '12px', md: '20px' },
          color: '#989A9E',
        }}
      >
        You still can go back and edit you answers!
      </CustomFontFamily>

      <Footer>
        <Button sx={{ color: '#fff' }} onClick={onBack}>
          <NavigateBeforeIcon />
        </Button>
        <Button sx={{ color: '#fff' }} onClick={onFinish}>
          Finish
        </Button>
      </Footer>
      <Box sx={{ display: { xs: 'none', md: 'flex' }, mt: '50px', px: '10px' }}>
        <Box sx={{ mx: '5px', width: '127px', height: '52px' }}>
          <CButton sx={{ width: '100%' }} onClick={onBack}>
            Back
          </CButton>
        </Box>
        <Box sx={{ mx: '5px', width: '127px', height: '52px' }}>
          <CButton onClick={onFinish}>Finish</CButton>
        </Box>
      </Box>
    </Box>
  );
}

export default VideoExplainer;
