import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from './component/HeaderMobile';
import HeaderDesktopForUser from './component/HeaderDesk';
import { formSelector } from '../../store/form/form.selectors';
import QuestionVideo from './component/QuestionVideo/index';

function FormContainer() {
  const form = useSelector(formSelector);
  const [isOpen, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <Box
      sx={{
        p: 0,
        display: { xs: 'flex', md: 'flex' },
        flexDirection: { xs: 'column', md: 'row' },
        overflow: { md: 'hidden' },
        height: { xs: '100vh', md: '100vh' },
      }}
    >
      {isOpen ? (<QuestionVideo open={isOpen} handleClose={() => setOpen(false)} />) : (<> </>)}
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Header cLogo={form?.job?.logo} onClickOpen={handleClickOpen} />
      </Box>
      <Box
        sx={{
          display: { xs: 'none', md: 'flex' },
          width: '116px',
          justifyContent: 'center',
        }}
      >
        <HeaderDesktopForUser cLogo={form?.job?.logo} onClickOpen={handleClickOpen} />
      </Box>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          overflowY: 'scroll',
          // pt: { md: '10%' }
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}

export default FormContainer;
