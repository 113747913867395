import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import moment from 'moment/moment';
import { CustomTypographyCandidate, CustomTypographyTitle } from '../../index.style';
import DescItem from './component/DescItem';
import DescItemEmail from './component/DescItemEmail';

function MobileDescription({ data }) {
  const [status, statusDate] = useMemo(() => {
    if (data.invited) return ['Invited', data.invited];
    if (data.declined) return ['Declined', data.declined];

    return [];
  }, [data]);

  return (
    <Box sx={{
      pb: '24px', display: { xs: 'flex', md: 'none' }, flexDirection: 'column', alignItems: 'center',
    }}
    >

      <DescItem value={data.name} label="Name" />
      <DescItemEmail value={data.email} label="Email" />
      <DescItem value={moment(parseInt(data.createdAt)).format('MM/DD/YYYY h:mm A')} label="Submitted" />

      {status
        ? (
          <>
            {' '}
            <CustomTypographyTitle pb="10px" sx={{ fontSize: { xs: '18px', md: '22px' } }}>
              {status}
            </CustomTypographyTitle>
            <CustomTypographyCandidate
              sx={{ mb: '10px', fontSize: { xs: '16px', md: '20px' }, lineHeight: { xs: '16px', md: '24px' } }}
            >
              {statusDate}
            </CustomTypographyCandidate>
            {' '}

          </>
        ) : <> </>}
    </Box>
  );
}

export default MobileDescription;
