import * as yup from 'yup';
import * as Constants from './constants';

export const ValidationSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email required'),
  password: yup
    .string()
    .min(Constants.MIN_PASSWORD_CHARACTERS, 'Password too short')
    .max(Constants.MAX_PASSWORD_CHARACTERS, 'Password too long')
    .required('Password required'),
});
