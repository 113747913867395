import { styled } from '@mui/material';
import CustomFontFamily from '../../components/TextStyle/index.style';

export const CustomTableTitle = styled(CustomFontFamily)({
  px: '10px',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '22px',
  /* identical to box height, or 157% */
  textAlign: 'start',
  color: '#989A9E',
});

export const CustomHeadTitle = styled(CustomFontFamily)({
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '36px',
  color: '#151515',
});

export const CustomOptionText = styled(CustomFontFamily)({
  fontWeight: 600,
  fontSize: '10px',
  lineHeight: '22px',
  textAlign: 'center',
});
