import { Box, Grid } from '@mui/material';
import React from 'react';
import { CustomTableTitle } from '../../index.style';

function TableHead() {
  return (
    <Box
      sx={{
        px: '20px',
        py: '10px',
        mb: '15px',
        display: { xs: 'none', md: 'flex' },

        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '5px',
        bgcolor: '#F1F1F1',
      }}
    >
      <Grid container spacing={1}>
        <Grid item md={2}>
          <CustomTableTitle>Name</CustomTableTitle>
        </Grid>

        <Grid item md={2}>
          <CustomTableTitle>Date</CustomTableTitle>
        </Grid>

        <Grid item md={2}>
          <CustomTableTitle>Email</CustomTableTitle>
        </Grid>

        <Grid item md={2}>
          <CustomTableTitle>Score</CustomTableTitle>
        </Grid>

        <Grid item md={2}>
          <CustomTableTitle>Status</CustomTableTitle>
        </Grid>

        <Grid item md={2} />
      </Grid>
    </Box>
  );
}

export default TableHead;
