function VideoViewer({ src }) {
  return (
    <video
      src={src}
      autoPlay
      controls
      playsInline
      style={{
        position: 'absolute',
        padding: '0',
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        objectFit: 'cover',
      }}
    />
  );
}

export default VideoViewer;
