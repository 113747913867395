import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import Video from '../../../../components/Video';
import ContentBox from '../ContentBox';
import VideoBox from '../../../../components/VideoBox';
import VideoViewer from '../../../../components/VideoViewer';
import Bottom from './component/Bottom';
import {
  formSelector,
  formVideoSelector,
} from '../../../../store/form/form.selectors';
import LoadingBox from './component/LoadingBox';
import { uploadVideo } from '../../../../store/form/form.thunks';

function Record({
  questionVideo, param, onFinish, onBack, direction, isFinal,
}) {
  const dispatch = useDispatch();
  const form = useSelector(formSelector);
  const videos = useSelector(formVideoSelector);
  const videoMetadata = videos[param] || {};
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  // direction -1 - means that we go backward
  const [step, setStep] = useState(direction === 1 ? 0 : 1);
  const [video, setVideo] = useState(null);

  const handleVideo = (video) => setVideo(video);

  const handleSubmit = async () => {
    if (video) {
      dispatch(uploadVideo(form.applicationId, param, video, isFinal));
    }

    if (video || videoMetadata.url || videoMetadata.uploading) {
      onFinish();
    }
  };

  const handlePrev = () => {
    if (isMd || step === 0) {
      onBack();
    } else {
      setStep(0);
    }
  };

  const handleNext = () => {
    if (isMd || step === 1) {
      handleSubmit();
    } else {
      setStep(1);
    }
  };

  return (
    <Box
      sx={{
        px: '34px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <ContentBox>
        {isMd || step === 0 ? (
          <Box sx={{ mr: { md: '20px' } }}>
            <VideoBox>
              <VideoViewer src={questionVideo} />
            </VideoBox>
          </Box>
        ) : (
          <></>
        )}
        {isMd || step === 1 ? (
          <VideoBox>
            {videoMetadata.uploading ? (
              <LoadingBox />
            ) : (
              <Video onVideo={handleVideo} previewVideo={videoMetadata.url} />
            )}
          </VideoBox>
        ) : (
          <></>
        )}
      </ContentBox>

      <Bottom
        onPrev={handlePrev}
        onNext={handleNext}
        nextDisabled={
          (isMd || step === 1)
          && !video
          && !videoMetadata.uploading
          && !videoMetadata.url
        }
      />
    </Box>
  );
}

export default Record;
