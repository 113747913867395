import {
  GET_JOB_INFO_URL,
  GET_ALL_JOB_URL,
  GET_JOB_URL,
  RESET_JOB_URL,
  DELETE_JOB_URL,
} from './constants';
import * as apiService from '../utils/apiService';

export class JobApiService {
  async getJobInfo(id) {
    const { data } = await apiService.axiosPublic.get(
      `${GET_JOB_INFO_URL}${id}`,
    );

    return data;
  }

  async getJob(id) {
    const { data } = await apiService.axiosPrivate.get(`${GET_JOB_URL}${id}`);

    return data;
  }

  async getAllJob() {
    const { data } = await apiService.axiosPrivate.get(GET_ALL_JOB_URL);

    return data;
  }

  async createJob(body) {
    const { data } = await apiService.axiosPrivate.post(GET_JOB_URL, body);
    return data;
  }

  async update(id, body) {
    const { data } = await apiService.axiosPrivate.patch(
      `${GET_JOB_URL}${id}`,
      body,
    );
    return data;
  }

  async reset(id, body) {
    const { data } = await apiService.axiosPrivate.patch(
      `${RESET_JOB_URL}${id}`,
      body,
    );
    return data;
  }

  async delete(id) {
    const { data } = await apiService.axiosPrivate.get(
      `${DELETE_JOB_URL}${id}`,
    );
    return data;
  }
}
export const jobApiService = new JobApiService();
