import React from 'react';
import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import logoDesk from './img/logoDesk.png';
import { CustomTitleDesk, CustomText } from './index.style';
import HomeIcon from '../HomeIcon';
import ProfileIcon from '../ProfileIcon';
import { profileSelectors } from '../../store/profile/profile.selectors';

function HeaderDesktop() {
  const logo = useSelector(profileSelectors.profileLogo);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        bgcolor: '#fff',
        boxShadow: ' 0px 2px 10px rgba(0, 0, 0, 0.1)',
        maxWidth: '116px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: '65px',
        }}
      >
        <Box sx={{ width: '90%' }}>
          <img style={{ maxWidth: '100%' }} src={logo || logoDesk} alt="logo" />
        </Box>
        <Divider sx={{ pt: '26px', width: '90%' }} />
        <Box sx={{ mt: '34px' }}>
          <NavLink to="/app/" style={{ textDecoration: 'none' }}>
            {({ isActive }) => (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <HomeIcon
                  active={!!isActive}
                  width="20px"
                  height="20px"
                />

                <CustomTitleDesk sx={{ pt: '4px', textDecoration: 'none' }}>
                  Job List
                </CustomTitleDesk>
              </Box>
            )}
          </NavLink>
        </Box>
      </Box>
      <Box sx={{ transform: 'rotate(-90deg)', whiteSpace: 'nowrap' }}>
        <CustomText>powered by intervuuio</CustomText>
      </Box>
      <Box sx={{ pb: '62px', display: 'flex', flexDirection: 'column' }}>
        <NavLink to="/app/profile">
          {({ isActive }) => (
            <ProfileIcon
              active={!!isActive}
              width="20px"
              height="20px"
            />
          )}
        </NavLink>
      </Box>
    </Box>
  );
}

export default HeaderDesktop;
