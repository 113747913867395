import './App.css';
import {
  BrowserRouter, Routes, Route, Navigate,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert, Backdrop, Box, CircularProgress,
} from '@mui/material';
import { useEffect } from 'react';
import MainContainer from './components/MainContainer';
import LoginContainer from './components/LoginContainer';
import SignUp from './scenes/SignUp';
import ResetPassword from './scenes/ResetPassword';
import Login from './scenes/Login';
import FormContainer from './components/FormContainer';
import Form from './scenes/Form';
import Jobs from './scenes/Jobs';
import Candidates from './scenes/Candidates';
import Candidate from './scenes/Candidate';
import Profile from './scenes/Profile';
import { errorSelector, loadingSelector } from './store/base/base.selectors';
import CreateJob from './scenes/CreateJob';
import { token as tokenSelector } from './store/auth/auth.selectors';
import { thunksProfile } from './store/profile/profile.thunks';
import UpdateJob from './scenes/UpdateJob';
import SetNewPassword from './scenes/SetNewPassword';

function App() {
  const loading = useSelector(loadingSelector);
  const token = useSelector(tokenSelector);
  const dispatch = useDispatch();
  const error = useSelector(errorSelector);

  useEffect(() => {
    if (token) {
      dispatch(thunksProfile.getProfile());
    }
  }, [dispatch, token]);

  return (
    <div className="App">
      <Box sx={{
        position: 'absolute', top: '5%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 50,
      }}
      >
        {error.map((e) => (
          <Alert severity="error" key={e.id}>
            {e}
          </Alert>
        ))}
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <BrowserRouter>
        <Routes>
          {token ? (
            <>
              <Route path="/app/" element={<MainContainer />}>
                <Route index element={<Jobs />} />
                <Route path="jobs" element={<Jobs />} />
                <Route path="candidates/all/:id" element={<Candidates />} />
                <Route path="candidates/:id" element={<Candidate />} />
                <Route path="profile" element={<Profile />} />
                <Route path="createjob/" element={<CreateJob />} />
                <Route path="updatejob/:id" element={<UpdateJob />} />
              </Route>
              <Route path="*" element={<Navigate to="/app/jobs" replace />} />
            </>
          ) : (
            <Route path="/" element={<LoginContainer />}>
              <Route index element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/resetpassword" element={<ResetPassword />} />
              <Route path="/setpassword/:id" element={<SetNewPassword />} />
              <Route path="*" element={<Navigate to="/login" replace />} />
            </Route>
          )}

          <Route path="/form" element={<FormContainer />}>
            <Route path="/form/:id" element={<Form />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
