import { Box, IconButton, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import CButton from '../../../../components/СButton';
import { CustomTextButton } from '../../index.style';

function HeaderJob({ onChange, onClick }) {
  return (
    <Box
      sx={{
        width: '100%',
        pb: '80px',
        display: { xs: 'none', md: 'flex' },
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box sx={{ width: '926px' }}>
        <TextField
          fullWidth
          placeholder="Job name"
          sx={{ mr: '0px', background: '#F1F1F1' }}
          onChange={onChange}
          InputProps={{
            startAdornment: (
              <IconButton sx={{}}>
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
      </Box>
      <Box sx={{ width: '186px', height: '52px', ml: '50px' }}>
        <CButton
          height="100%"
          onClick={onClick}
        >
          <AddIcon sx={{ fontSize: '30px' }} />
          <CustomTextButton>New Job</CustomTextButton>
        </CButton>
      </Box>
    </Box>
  );
}

export default HeaderJob;
