import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';

function QuestionVideo({ open, handleClose }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ zIndex: 9999 }}
    >
      <DialogTitle>
        Describe
      </DialogTitle>
      <DialogContent sx={{ width: { xs: '290px', md: '500px' }, height: '350px' }}>
        <iframe width="100%" height="100%" style={{ borderRadius: '10px' }} src="https://www.youtube.com/embed/mDr1j6LnSqo" />
      </DialogContent>
    </Dialog>
  );
}

export default QuestionVideo;
