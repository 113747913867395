import React from 'react';
import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import logoDesk from './img/logoDesk.png';
import { CustomText } from '../../../HeaderDesktop/index.style';
import { MAIN_COLOR } from '../../../../config/config';

function HeaderDesktopForUser({ cLogo, onClickOpen }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        bgcolor: '#fff',
        boxShadow: ' 0px 2px 10px rgba(0, 0, 0, 0.1)',
        maxWidth: '116px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: '65px',
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <img src={cLogo || logoDesk} alt="logo" style={{ maxWidth: '90%' }} />
        </Box>
        <Divider sx={{ pt: '26px', width: '100%' }} />
        <Box sx={{ pt: '34px' }}>{/* <HomeIcon active={true} /> */}</Box>

        {/* <CustomTitleDesk sx={{ pt: '4px' }} >job List</CustomTitleDesk> */}
      </Box>
      <Box sx={{ transform: 'rotate(-90deg)', whiteSpace: 'nowrap' }}>
        <CustomText>powered by intervuuio</CustomText>
      </Box>
      <Box sx={{ pb: '62px', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ pb: '36px' }}>{/* / <SettingIcon active={true} /> */}</Box>
        <Box>{/* <ProfileIcon active={true} /> */}</Box>
        <Box>
          <HelpOutlineIcon sx={{ color: MAIN_COLOR }} onClick={onClickOpen} />
        </Box>
      </Box>
    </Box>
  );
}

export default HeaderDesktopForUser;
