import { actions as candidatesActions } from './candidates.actions';

const initialState = {
  applications: [],
};

export const candidatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case candidatesActions.getAllCandidateInitialized.type:
      return state;
    case candidatesActions.getAllCandidateSuccessful.type: {
      const sortedUsers = action.payload.Applications.sort((a, b) => b.rating - a.rating);
      return {
        ...state,
        applications: sortedUsers,
      }; }
    case candidatesActions.getCandidateInitialized.type:
      return state;
    case candidatesActions.getCandidateSuccessful.type:
      return {
        ...state,
        ...action.payload,
        // jobId: action.payload.jobId,
        // createdAt: action.payload.createdAt,
        // rating: action.payload.rating,
        // resume: action.payload.resume,
        // invitedToInterview: action.payload.invitedToInterview,
        // introductionVideo: action.payload.introductionVideo,
        // email: action.payload.email,
        // archived: action.payload.archived,
        // name: action.payload.name,
        // photo: action.payload.photo,
      };
    case candidatesActions.updateRatingCandidateInitialized.type:
      return state;
    case candidatesActions.updateRatingCandidateSuccessful.type:
      return {
        ...state,
        rating: action.payload.rating,
      };
    case candidatesActions.deleteCandidateInitialized.type:
      return state;
    case candidatesActions.deleteCandidateSuccessful.type: {
      const data = [...state.applications];
      const item = data.filter((x) => x.id !== action.payload);
      return { ...state, applications: item };
    }
    case candidatesActions.updateNoteInitialized.type:
      return state;
    case candidatesActions.updateNoteSuccessful.type:
      return {
        ...state,
        note: action.payload.note,
      };
    default:
      return state;
  }
};
