import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import Recorder from './components/Recorder';
import Viewer from './components/Viewer';
import UploadButton from './components/UploadButton';
import ResetButton from './components/ResetButton';
// import getVideoDuration from "./components/Duration/duration";
import { addError } from '../../store/base/base.thunks';

function Video({
  onVideo, previewVideo, previewAutoplay, noLimit,
}) {
  const [preview, setPreview] = useState(null);
  const dispatch = useDispatch();
  const [recording, setRecording] = useState(false);

  useEffect(() => {
    // if (previewVideo) {
    setPreview(previewVideo);
    // }
  }, [previewVideo]);

  const handleReset = () => {
    setPreview(null);
  };

  const getVideoDuration = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const media = new Audio(reader.result);
      media.onloadedmetadata = () => resolve(media.duration);
    };
    reader.readAsDataURL(file);
    reader.onerror = (error) => reject(error);
  });

  const handleVideo = async ({ file, type }) => {
    if (type === 'upload') {
      const duration = await getVideoDuration(file);

      if (!noLimit && duration > 60) {
        dispatch(addError('Please add video duration up to 1 min'));
        return;
      }
    }

    setPreview(URL.createObjectURL(file));
    setRecording(false);

    if (onVideo) {
      onVideo(file);
    }
  };

  let button;

  if (!recording) {
    if (preview) {
      button = <ResetButton onClick={handleReset} />;
    } else {
      button = (
        <UploadButton onChange={(e) => handleVideo({ file: e.target.files[0], type: 'upload' })} />
      );
    }
  }

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
      component="form"
    >
      <Box>
        {preview ? (
          <Viewer src={preview} disableAutoplay={!previewAutoplay} />
        ) : (
          <Recorder
            noLimit={noLimit}
            onFinish={handleVideo}
            onRecording={() => setRecording(true)}
          />
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pt: '20px',
        }}
      >
        <Box justifyContent="center" display="flex" flexDirection="row">
          {button}
        </Box>
      </Box>
    </Box>
  );
}

export default Video;
