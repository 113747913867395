function ResetIcon() {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.57143 0.571411C6.86647 0.571411 5.23133 1.24871 4.02574 2.4543C2.82015 3.65989 2.14286 5.29502 2.14286 6.99998H0L2.85714 9.85713L5.71429 6.99998H3.57143C3.57143 4.24284 5.81429 1.99998 8.57143 1.99998C11.3286 1.99998 13.5714 4.24284 13.5714 6.99998C13.5714 9.75713 11.3286 12 8.57143 12C7.21429 12 5.98571 11.4571 5.08571 10.5786L4.07143 11.5857C5.2715 12.7667 6.88772 13.4285 8.57143 13.4286C10.2764 13.4286 11.9115 12.7513 13.1171 11.5457C14.3227 10.3401 15 8.70495 15 6.99998C15 5.29502 14.3227 3.65989 13.1171 2.4543C11.9115 1.24871 10.2764 0.571411 8.57143 0.571411ZM10 6.2857V5.57141C10 4.7857 9.35714 4.14284 8.57143 4.14284C7.78571 4.14284 7.14286 4.7857 7.14286 5.57141V6.2857H6.42857V9.85713H10.7143V6.2857H10ZM9.28571 6.2857H7.85714V5.57141C7.85714 5.17855 8.17857 4.85713 8.57143 4.85713C8.96429 4.85713 9.28571 5.17855 9.28571 5.57141V6.2857Z"
        fill="white"
      />
    </svg>
  );
}

export default ResetIcon;
