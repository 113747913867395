import { Box } from '@mui/material';
import React from 'react';

function Rating(props) {
  const { rating } = props;

  let color = '#989A9E';

  if (rating >= 8) {
    color = '#54BA8F';
  } else if (rating >= 5) {
    color = '#FFA800';
  } else if (rating > 0) {
    color = '#FF0909';
  }

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
    }}
    >
      <Box
        sx={{
          bgcolor: color,
          width: '10px',
          height: '10px',
          fontWeight: '600',
          borderRadius: '50%',
          fontSize: '14px',
          lineHeight: '22px',
          // identical to box height, or 157%
          textAlign: 'start',
          color: '#FFFFFF',
        }}
      />
      <Box sx={{ px: 1 }}>{rating || ''}</Box>
    </Box>
  );
}

export default Rating;
