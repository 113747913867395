import * as yup from 'yup';
import * as Constants from './constants';

export const ValidationSchema = yup.object().shape({
  newPassword: yup
    .string()
    .min(Constants.MIN_PASSWORD_CHARACTERS, 'Password too short')
    .max(Constants.MAX_PASSWORD_CHARACTERS, 'Password too long')
    .required('Password required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Password does not match')
    .required('You need to confirm your password'),
});
