import { Grid, Box, Checkbox } from '@mui/material';
import React from 'react';
import saveVideo from './img/saveVideo.svg';
import reset from './img/reset.svg';
import CButton from '../../../components/СButton';
import Video from '../../../components/Video';
import { CustomTitleForVideJob } from '../../CreateJob/index.style';
import { CustomInputJob } from './index.style';
import VideoBox from '../../../components/VideoBox';

function VideoInput({
  param,
  label,
  onSave,
  initialVideo,
  onReset,
  desc,
  name,
  onChange,
  onStatusChange,
  enabledParam,
  enabled,
}) {
  return (
    <Grid
      item
      xs={12}
      sx={{
        m: '20px',
        p: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: { xs: 'center', md: 'flex-start' },
        flexDirection: { xs: 'column', md: 'row' },
        boxShadow: { xs: 'none', md: '0px 2px 10px rgba(0, 0, 0, 0.1)' },
        borderRadius: '5px',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {enabled ? (
        <></>
      ) : (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(191,191, 191, 0.7)',
            zIndex: '9999',
            display: { xs: 'none', md: 'block' },
          }}
        />
      )}

      <Box
        sx={{
          position: 'relative',
          left: 0,
          top: 0,
          zIndex: '99999',
          display: { xs: 'none', md: 'block' },
        }}
      >
        <Box
          sx={{
            background: '#fff',
            position: 'absolute',
            width: '17px',
            height: '17px',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
        <Checkbox
          name={enabledParam}
          sx={{ p: 0 }}
          onChange={(e) => onStatusChange(enabledParam, e.target.checked)}
          checked={!!enabled}
        />
      </Box>

      <Box
        sx={{
          width: { xs: '301px', md: '330px', lg: '416px' },
          mx: { xs: '0', md: 'auto' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            background: { xs: '#FFFFFF', md: 'none' },
            boxShadow: {
              xs: '0px 2px 10px rgba(0, 0, 0, 0.1)',
              md: 'none',
            },
            borderRadius: '5px',
            p: { xs: '15px', md: '0' },
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          {enabled ? (
            <></>
          ) : (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(191,191, 191, 0.7)',
                zIndex: '9999',
                display: { md: 'none' },
              }}
            />
          )}

          <Box
            sx={{
              position: 'relative',
              left: 0,
              top: 0,
              zIndex: '99999',
              display: { xs: 'block', md: 'none' },
            }}
          >
            <Box
              sx={{
                background: '#fff',
                position: 'absolute',
                width: '17px',
                height: '17px',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
            <Checkbox
              name={enabledParam}
              sx={{ p: 0 }}
              onChange={(e) => onStatusChange(enabledParam, e.target.checked)}
              checked={!!enabled}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Box sx={{ mb: 1 }}>
              <CustomTitleForVideJob>{label}</CustomTitleForVideJob>
            </Box>

            <Box sx={{ width: '100%', mb: '15px' }}>
              <CustomInputJob
                name={name}
                value={desc}
                multiline
                onChange={(e) => onChange(name, e.target.value)}
              />
            </Box>

            <VideoBox>
              <Video
                onVideo={(video) => onChange(param, video)}
                previewVideo={initialVideo}
                param={param}
                noLimit
              />
            </VideoBox>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            mt: '15px',
          }}
        >
          <Box>
            <CButton
              type="submit"
              sx={{
                px: '10px',
                mx: '10px',
                minHeight: { xs: '32px', md: '42px' },
                width: { xs: '80px', md: '150px' },
              }}
              onClick={() => onSave(param, name)}
            >
              <img
                style={{ paddingRight: '10px' }}
                src={saveVideo}
                alt="pass"
              />
              {' '}
              Save
            </CButton>
          </Box>
          <Box>
            <CButton
              sx={{
                px: '10px',
                mx: '10px',
                minHeight: { xs: '32px', md: '42px' },
                width: { xs: '80px', md: '150px' },
              }}
              onClick={() => onReset && onReset(param, name)}
            >
              <img style={{ paddingRight: '10px' }} src={reset} alt="pass" />
              {' '}
              Reset
            </CButton>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}

export default VideoInput;
