import { Button, styled } from '@mui/material';
import { MAIN_COLOR } from '../../config/config';

const CustomButtonStyle = styled(Button)({
  fontFamily: 'Poppins',
  background: MAIN_COLOR,
  boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, 0.05)',
  borderRadius: '5px',
  color: '#FFFFFF',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '22px',
  minHeight: '32px',
  textTransform: 'none',
  '&:hover': {
    background: '#FF6767',
    boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, 0.05)',
  },
  '&:pressed': {
    background: '#CA4040',
    boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, 0.05)',
  },
  '&:focus': {
    border: '4px solid rgba(235, 80, 80, 0.25)',
    boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, 0.05)',
    background: '#EB5050',
  },
  '&:loading': {
    background: '#989A9E',
    border: '4px solid rgba(235, 80, 80, 0.25)',
    boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, 0.05)',
  },
  '&:disabled': {
    background: '#989A9E',
    boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, 0.05)',
  },
});

export default CustomButtonStyle;
