import { Box, Button, Dialog } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { CustomTextModal, CustomTextModalBlock } from './index.style';

function Modal({
  link, jobTitle, company, onClose,
}) {
  const text = `Thank you for applying for the ${jobTitle} at ${company}. To be considered for a one-on-one interview, please submit your resume and a video introducing yourself. To get started, click the following link: \n\n ${link}`;
  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '5px',
          width: '325px',
          height: 'auto',
          px: 2,
          py: '20px',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <CustomTextModal>Message</CustomTextModal>
        <Button onClick={() => navigator.clipboard.writeText(text)}>
          <FileCopyIcon
            style={{ color: '#151515', fontSize: '20px' }}
            sx={{ pr: '5px' }}
          />
          <CustomTextModal>Copy </CustomTextModal>
        </Button>
      </Box>
      <Box
        sx={{
          border: '1px solid #CBCDD0',
          borderRadius: '5px',
          minWidth: '285px',
          height: 'auto',
          p: '10px',
        }}
      >
        <CustomTextModalBlock id="mainText" sx={{ wordWrap: 'break-word' }}>
          Thank you for applying for the
          {' '}
          {jobTitle}
          {' '}
          at
          {' '}
          {company}
          . To be
          considered for a one-on-one interview, please submit your resume and a
          video introducing yourself. To get started, click the following link:
          <br />
          <br />
          {link}
        </CustomTextModalBlock>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pt: '10px',
        }}
      >
        <CustomTextModal>Link</CustomTextModal>
        <Button onClick={() => navigator.clipboard.writeText(link)}>
          <FileCopyIcon
            style={{ color: '#151515', fontSize: '20px' }}
            sx={{ pr: '5px' }}
          />
          <CustomTextModal>Copy</CustomTextModal>
        </Button>
      </Box>
      <Box
        sx={{
          border: '1px solid #CBCDD0',
          borderRadius: '5px',
          minWidth: '285px',
          height: 'auto',
          p: '10px',
          wordWrap: 'break-word',
        }}
      >
        <CustomTextModalBlock>{link}</CustomTextModalBlock>
      </Box>
    </Dialog>
  );
}

export default Modal;
