import { createAction } from '@reduxjs/toolkit';

const formInitialized = createAction('form/initialized');
const formLoaded = createAction('form/loaded');
const formApplicationCreated = createAction('form/applicationCreated');
const formNextStep = createAction('form/nextStep');
const formPrevStep = createAction('form/prevStep');
const formUploading = createAction('form/uploading');
const formUploaded = createAction('form/uploaded');

export const actions = {
  formInitialized,
  formLoaded,
  formApplicationCreated,
  formNextStep,
  formPrevStep,
  formUploading,
  formUploaded,
};
