import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/material';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CButton from '../../components/СButton';
import CInput from '../../components/CInput';
import { ValidationSchema } from './PasswordValidationSchemas';
import { thunksProfile } from '../../store/profile/profile.thunks';
import pass from './img/password.svg';

function SetNewPassword() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [done, setDone] = useState(false);

  return (

    <Box>
      {done
        ? (
          <Box sx={{
            height: '100%',
            top: '50%',
          }}
          >
            <CheckCircleOutlineIcon sx={{ fontSize: { xs: '100px', md: '150px' }, color: 'green' }} />
          </Box>
        )
        : (
          <>
            <Box
              sx={{
                position: 'absolute',
                opacity: '0.6',
                zIndex: 10,
                top: '-47px',
                left: 0,
              }}
            />
            <Formik
              initialValues={{ newPassword: '', confirmPassword: '' }}
              validationSchema={ValidationSchema}
              onSubmit={(values) => {
                dispatch(thunksProfile.newPassword(
                  { newPassword: values.newPassword, forgotPasswordId: id },
                ));
                setDone(true);
                setTimeout(() => {
                  navigate('./login');
                }, '3000');
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <CInput
                    placeholder="Password"
                    sx={{ mb: '11px' }}
                    type="password"
                    name="newPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.newPassword}
                    InputProps={{
                      startAdornment: (
                        <IconButton sx={{ left: '8px', pr: '20px' }}>
                          <img src={pass} alt="message" />
                        </IconButton>
                      ),
                    }}
                  />
                  {errors.password && touched.password && errors.password}
                  <CInput
                    placeholder="Confirm Password"
                    sx={{ mb: '12px' }}
                    type="password"
                    name="confirmPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                    InputProps={{
                      startAdornment: (
                        <IconButton sx={{ left: '8px', pr: '20px' }}>
                          <img src={pass} alt="message" />
                        </IconButton>
                      ),
                    }}
                  />
                  {errors.confirmPassword
                && touched.confirmPassword
                && errors.confirmPassword}
                  <CButton type="submit" disabled={isSubmitting} height="48px">
                    Submit
                  </CButton>
                </form>
              )}
            </Formik>
          </>
        )}
    </Box>
  );
}

export default SetNewPassword;
