import { Box } from '@mui/material';
import React from 'react';
import { CustomCandidateText } from '../../../../components/TextStyle/component/index.style';
import { CustomTypographyCandidate } from '../../index.style';

function ItemQuestion({ title, video, desc }) {
  if (!video) {
    return <></>;
  }

  return (
    <Box sx={{ pb: { xs: '30px', md: '100px' } }}>
      <CustomCandidateText
        sx={{
          textAlign: 'start',
          pb: '10px',
          fontSize: { md: '20px' },
          lineHeight: { md: '24px' },
        }}
      >
        {title}
      </CustomCandidateText>
      <CustomTypographyCandidate sx={{ mb: '10px', width: { xs: '290px', md: '716px' }, fontSize: { xs: '14px', md: '22px' } }}>{desc}</CustomTypographyCandidate>
      <Box
        sx={{
          width: { xs: '286px', md: '716px' },
          height: { xs: '429px', md: '614px' },
          background: 'linear-gradient(0deg, #636363, #636363)',
          backgroundBlendMode: 'multiply, normal',
          boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
          borderRadius: '10px',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <video
          controls
          src={video}
          playsInline
          style={{
            position: 'absolute',
            padding: '0',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </Box>
    </Box>
  );
}

export default ItemQuestion;
