import React from 'react';
import { Box, Button } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Footer from '../../../../components/Footer';
import CButton from '../../../../components/СButton';
import ContentBox from '../ContentBox';
import VideoBox from '../../../../components/VideoBox';
import VideoViewer from '../../../../components/VideoViewer';

function VideoExplainer({ video, onFinish }) {
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <ContentBox single>
        <VideoBox>
          <VideoViewer src={video} />
        </VideoBox>
      </ContentBox>

      <Footer
        sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end' }}
      >
        <Button sx={{ color: '#fff', marginLeft: '100%' }} onClick={onFinish}>
          <NavigateNextIcon />
        </Button>
      </Footer>
      <Box sx={{ display: { xs: 'none', md: 'flex' }, mt: '50px', px: '10px' }}>
        <Box sx={{ mx: '5px', width: '127px', height: '52px' }}>
          <CButton onClick={onFinish}>Next </CButton>
        </Box>
      </Box>
    </Box>
  );
}

export default VideoExplainer;
