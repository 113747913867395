import { TextField, styled } from '@mui/material';
import CustomFontFamily from '../../components/TextStyle/index.style';
import { SECONDARY_COLOR } from '../../config/config';

export const CustomInputCandidate = styled(TextField)({
  width: '286px',
  background: '#FFFFFF',
  /* Grey Light */
  border: '1px solid #CBCDD0',
  borderRadius: '5px',
});

export const CustomFooter = styled(CustomFontFamily)({
  fontWeight: '700',
  fontSize: '11px',
  lineHeight: '22px',
  textAlign: 'center',
  color: '#ffffff',
});

export const CustomTypographyCandidate = styled(CustomFontFamily)({
  fontSize: '22px',
  color: SECONDARY_COLOR,
});

export const CustomTypographyTitle = styled(CustomFontFamily)({
  fontWeight: '700',
  fontSize: '22px',
  lineHeight: '22px',
  color: '#989A9E',
});
