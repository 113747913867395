import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import Finish from './component/Finish';
import Wait from './component/Wait';
import { formVideoSelector } from '../../../../store/form/form.selectors';

function Done() {
  const videos = useSelector(formVideoSelector);

  const isUploading = () => {
    for (const param in videos) {
      if (videos[param].uploading) {
        return true;
      }
    }

    return false;
  };

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      {isUploading() ? <Wait /> : <Finish />}
    </Box>
  );
}

export default Done;
