import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import moment from 'moment/moment';
import Rating from '../Rating';
import { CustomInputCandidate, CustomTypographyCandidate, CustomTypographyTitle } from '../../index.style';
import CButton from '../../../../components/СButton';
import ItemDescription from './component/ItemDescription';
import ItemDescriptionEmail from './component/ItemDescriotionEmail';

function DesktopDescription({
  data, onRatingChange, onRatingSubmit, data1, onChange, value, onClick,
}) {
  const [status, statusDate] = useMemo(() => {
    if (data.invited) return ['Invited', data.invited];
    if (data.declined) return ['Declined', data.declined];

    return [];
  }, [data]);

  return (
    <>
      <Box sx={{ display: { xs: 'none', md: 'flex' }, pb: '10px' }}>
        <Box>
          <ItemDescription label="Name" value={data.name} />
          <ItemDescriptionEmail label="Email" value={data.email} />
          <ItemDescription label="Submitted:" value={moment(parseInt(data.createdAt)).format('MM/DD/YYYY h:mm A')} />
          {status
            ? (
              <>
                <CustomTypographyTitle pb="10px">
                  {status}
                </CustomTypographyTitle>
                <CustomTypographyCandidate
                  sx={{ mb: '10px', fontSize: { xs: '16px', md: '20px' }, lineHeight: { xs: '16px', md: '24px' } }}
                >
                  {statusDate}
                </CustomTypographyCandidate>
                {' '}

              </>
            ) : <> </>}
        </Box>
        <Rating
          id={1}
          data={data1}
          onRatingChange={onRatingChange}
          onRatingSubmit={onRatingSubmit}
        />
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Rating
          id={2}
          data={data1}
          onRatingChange={onRatingChange}
          onRatingSubmit={onRatingSubmit}
        />
      </Box>
      <Box sx={{ maxWidth: '716px', mb: '15px' }}>
        <CustomTypographyTitle sx={{
          textAlign: 'start',
          pb: '10px',
          fontSize: { md: '20px' },
          lineHeight: { md: '24px' },
        }}
        >
          Note
        </CustomTypographyTitle>

        <CustomInputCandidate
          name="note"
          value={value}
          onChange={onChange}
          sx={{ mb: '10px', width: { xs: '100%', md: '716px' } }}
        />
        <CButton sx={{ maxWidth: '716px' }} onClick={onClick}>Save</CButton>
      </Box>
    </>
  );
}

export default DesktopDescription;
