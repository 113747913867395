import { Box } from '@mui/material';

function VideoBox({ children }) {
  return (
    <Box
      sx={{
        width: { xs: '286px', md: '330px', lg: '416px' },
        height: { xs: '429px', md: '283px', lg: '357px' },
        maxWidth: '100%',
        background: 'linear-gradient(0deg, #636363, #636363)',
        backgroundBlendMode: 'multiply, normal',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        position: 'relative',
        overflow: 'hidden',
        zIndex: 0,
        flexShrink: '1',
      }}
    >
      {children}
    </Box>
  );
}

export default VideoBox;
