import { styled } from '@mui/material';
import CustomFontFamily from '../../../components/TextStyle/index.style';
import { MAIN_COLOR } from '../../../config/config';

export const FormTitle = styled(CustomFontFamily)({
  fontWeight: '700',
  fontSize: '12px',
  textAlign: 'center',
  color: MAIN_COLOR,
});

export const FormDescription = styled(CustomFontFamily)({
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'center',
  color: '#989A9E',
});

export const JobTitleInForm = styled(CustomFontFamily)({
  fontWeight: '600',
  fontSize: '20px',
  textAlign: 'center',
  color: MAIN_COLOR,
});
