import React from 'react';
import { Box, Grid } from '@mui/material';
import { Formik, Form } from 'formik';
import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CInput from '../../components/CInput';
import CButton from '../../components/СButton';
import message from './img/message.svg';
import pass from './img/password.svg';

import {
  LoginLink,
  LoginLinkA,
  LoginLinkS,
  LoginTitle,
} from '../../components/LoginContainer/index.style';
import { ValidationSchema } from './LoginValidationSchemas';
import { thunks } from '../../store/auth/auth.thunks';

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    await dispatch(thunks.login(values));
    // TODO: check later because don`t work
    navigate('/app');
  };
  return (
    <Box>
      <Box
        sx={{
          position: 'absolute',
          opacity: '0.6',
          zIndex: 10,
          top: '-47px',
          left: 0,
        }}
      />
      <LoginTitle sx={{ pb: '28px', display: { xs: 'block', md: 'none' } }}>
        Login
      </LoginTitle>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={ValidationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form>
            <CInput
              placeholder="Email"
              sx={{ mb: '10px' }}
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={errors.email}
              InputProps={{
                startAdornment: (
                  <IconButton sx={{ left: '8px', pr: '20px' }}>
                    <img src={message} alt="message" />
                  </IconButton>
                ),
              }}
            />
            <CInput
              placeholder="Password"
              sx={{ mb: '12px' }}
              type="password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              error={errors.password}
              InputProps={{
                startAdornment: (
                  <IconButton sx={{ left: '8px', pr: '20px', pl: '10px' }}>
                    <img src={pass} alt="message" />
                  </IconButton>
                ),
              }}
            />
            <CButton type="submit" disabled={isSubmitting} height="48px">
              Login
            </CButton>
          </Form>
        )}
      </Formik>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ my: '12px' }}
      >
        <Grid item xs={12}>
          <Link to="/resetpassword" variant="body2">
            <LoginLink>Forgot Password?</LoginLink>
          </Link>
        </Grid>
        <Grid item xs={12} mt={2} sx={{ mt: '8px' }}>
          <LoginLinkA>Don`t have an account?</LoginLinkA>
          <Link to="/signup" variant="body2" style={{ textDecoration: 'none' }}>
            <LoginLinkS>Sign Up</LoginLinkS>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}
export default Login;
