import React from 'react';
import { Box, Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import logo from './img/logo.png';
import CustomTitleLogo from './index.style';
import HomeIcon from '../HomeIcon';
import ProfileIcon from '../ProfileIcon';
import { profileSelectors } from '../../store/profile/profile.selectors';

function HeaderMobile() {
  const personalLogo = useSelector(profileSelectors.profileLogo);
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        height: '59px',
        px: '40px',
        pt: '9px',
      }}
    >
      <Button>
        <NavLink to="/app/">
          {({ isActive }) => (
            <HomeIcon
              active={!!isActive}
              width="20px"
              height="20px"
            />
          )}
        </NavLink>
      </Button>
      <Box
        sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
      >
        {personalLogo ? (
          <img style={{ maxHeight: '26px' }} src={personalLogo} alt="logo" />
        ) : (
          <img style={{ maxHeight: '26px' }} src={logo} alt="logo" />
        )}
        <CustomTitleLogo>powered by intervuuio</CustomTitleLogo>
      </Box>
      <Button>
        <NavLink to="/app/profile">
          {({ isActive }) => (
            <ProfileIcon
              active={!!isActive}
              width="20px"
              height="20px"
            />
          )}
        </NavLink>
      </Button>
    </Box>
  );
}

export default HeaderMobile;
