import { styled, TextField } from '@mui/material';
import { MAIN_COLOR } from '../../config/config';

const CustomInput = styled(TextField)({
  height: '52px',
  '& label.Mui-focused': {
    color: MAIN_COLOR,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'green',
  },
  background: '#FFFFFF',
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
  borderRadius: '5px',
  '& .MuiOutlinedInput-root': {
    paddingLeft: '5px',
    height: '52px',
    fontSize: '14px',
    fontFamily: 'Poppins',
    fontWeight: '600',
    '& + fieldset': {
      borderColor: '#EFF1F6',
    },
    '&:hover + fieldset': {
      border: '1px solid #989A9E',
      boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, 0.05)',
    },
    '&.Mui-focused + fieldset': {
      border: '1px solid #EB5050',
      boxShadow: '0px 0px 10px rgba(235, 80, 80, 0.5)',
    },
    '&:success + fieldset': {
      border: '1px solid #5BC090',
      boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, 0.05)',
      borderRadius: '5px',
    },
    '&:success ': {
      border: '1px solid #5BC090',
      boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, 0.05)',
      borderRadius: '5px',
    },
  },
});

export default CustomInput;
