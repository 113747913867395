import { styled } from '@mui/material';
import CustomFontFamily from '../../components/TextStyle/index.style';

export const CustomTextButton = styled(CustomFontFamily)({
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '22px',
  textAlign: 'center',
  color: '#FFFFFF',
});
