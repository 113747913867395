import React from 'react';
import { Link } from '@mui/material';
import { CustomTypographyCandidate, CustomTypographyTitle } from '../../../../index.style';

function ItemDescriptionEmail({ label, value }) {
  return (
    <>
      <CustomTypographyTitle pb="10px">
        {label}
      </CustomTypographyTitle>
      <Link href={`mailto:${value}`}>
        <CustomTypographyCandidate pb="10px">
          {value}
        </CustomTypographyCandidate>
      </Link>
    </>
  );
}

export default ItemDescriptionEmail;
