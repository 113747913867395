import { Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Done from '@mui/icons-material/Done';
import { useFormik } from 'formik';
import LogoutIcon from '@mui/icons-material/Logout';
import Footer from '../../components/Footer';
import CButton from '../../components/СButton';
import { profileSelectors } from '../../store/profile/profile.selectors';
import { thunksProfile } from '../../store/profile/profile.thunks';
import 'react-toastify/dist/ReactToastify.css';
import { CustomTextButton } from '../Jobs/index.style';
import TitleDesktop from '../../components/TitleDesktop';
import ImageItem from './component/ImageItem';
import InputItem from './component/InputItem';
import { thunks } from '../../store/auth/auth.thunks';

function Profile() {
  const dispatch = useDispatch();
  const data = useSelector(profileSelectors.profile);

  const logout = () => {
    dispatch(thunks.logout());
  };

  const [fileImg, setFileImage] = useState(null);
  const [image, setImage] = useState(null);

  const onFile = (e) => {
    const [file] = e.target.files;
    setImage(URL.createObjectURL(file));

    const dataFile = new FormData();
    dataFile.append('logo', file);

    setFileImage(dataFile);
  };

  useEffect(() => {
    dispatch(thunksProfile.getProfile());
  }, []);

  const styles = {
    color: '#989A9E',
  };
  const stylesDone = {
    color: '#fff',
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    onSubmit: async (values) => {
      if (values) {
        await dispatch(thunksProfile.updateProfile(values));
      }

      if (fileImg) {
        await dispatch(thunksProfile.updateProfileLogo(fileImg));
      }

      dispatch(thunksProfile.getProfile());
    },
  });

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        pt: { xs: '10px', md: '100px' },
        pb: { xs: '85px', md: '10px' },
        px: { xs: '30px', md: '108px' },
      }}
    >
      <Box sx={{ width: '100%' }}>
        <TitleDesktop title="Profile">
          <Box sx={{ minWidth: '186px', pr: '10px' }}>
            <CButton onClick={logout} height="40px">
              <CustomTextButton>Logout</CustomTextButton>
            </CButton>
          </Box>
          <Box sx={{ minWidth: '186px' }}>
            <CButton height="40px" type="submit">
              <CustomTextButton>Complete </CustomTextButton>
            </CButton>
          </Box>
        </TitleDesktop>
      </Box>
      <Box
        sx={{
          width: '95%',
          maxWidth: '400px',
          m: 'auto',
        }}
      >
        <Box
          sx={{
            px: '0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <ImageItem image={image} logo={data.logo} onChange={onFile} style={styles} />
          <InputItem formik={formik} />
          <Footer>
            <Button onClick={logout} sx={{ color: '#fff' }} height="40px" type="submit">
              <LogoutIcon />
            </Button>
            <Button style={stylesDone} type="submit" sx={{ color: '#fff' }}>
              <Done fontSize="large" />
            </Button>
          </Footer>
        </Box>
      </Box>
    </Box>
  );
}

export default Profile;
