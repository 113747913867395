import React from 'react';
import home from './img/home.svg';

function HomeIcon({ active, width, height }) {
  return (
    <>
      {active ? (
        <img
          src={home}
          style={{
            width,
            height,
            // TODO: Check SVG
            filter:
              'invert(55%) sepia(61%) saturate(5289%) hue-rotate(334deg) brightness(102%) contrast(84%)',
          }}
          alt="homeActive"
        />
      ) : (
        <img src={home} style={{ width, height }} alt="home" />
      )}
    </>
  );
}

export default HomeIcon;
