import { styled } from '@mui/material';
import CustomFontFamily from '../TextStyle/index.style';

const CustomTitleLogo = styled(CustomFontFamily)({
  fontWeight: '600',
  fontSize: '8px',
  lineHeight: '22px',
  textAlign: 'center',
  textTransform: 'uppercase',
});

export default CustomTitleLogo;
