import { Box } from '@mui/material';

function Footer({ sx = {}, children }) {
  return (
    <Box
      sx={{
        display: { xs: 'flex', md: 'none' },
        position: 'fixed',
        justifyContent: 'space-between',
        alignItems: 'center',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '76px',
        background: 'rgba(255, 86, 86, 0.9)',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        backdropFilter: 'blur(4px)',
        color: '#FFF',
        px: '39px',
        zIndex: 999999,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
export default Footer;
