import { Box, Button } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Footer from '../../../../../../components/Footer';
import CButton from '../../../../../../components/СButton';

function Bottom({ onPrev, onNext, nextDisabled }) {
  return (
    <>
      <Footer>
        <Button sx={{ color: '#fff' }} onClick={onPrev}>
          <NavigateBeforeIcon />
        </Button>
        <Button sx={{ color: '#fff' }} onClick={onNext} disabled={nextDisabled}>
          <NavigateNextIcon />
        </Button>
      </Footer>
      <Box sx={{ display: { xs: 'none', md: 'flex' }, mt: '50px', px: '10px' }}>
        <Box sx={{ mx: '5px', width: '127px', height: '52px' }}>
          <CButton sx={{ width: '100%' }} onClick={onPrev}>
            Back
          </CButton>
        </Box>
        <Box sx={{ mx: '5px', width: '127px', height: '52px' }}>
          <CButton onClick={onNext}>Next</CButton>
        </Box>
      </Box>
    </>
  );
}

export default Bottom;
