import { Box, Button } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Footer from '../../../../../../components/Footer';
import CButton from '../../../../../../components/СButton';

function Bottom({ step, onPrev, onNext }) {
  return (
    <>
      <Footer>
        <Button disabled={step === 0} sx={{ color: '#fff' }} onClick={onPrev}>
          <NavigateBeforeIcon />
        </Button>
        <Button sx={{ color: '#fff' }} onClick={onNext}>
          <NavigateNextIcon />
        </Button>
      </Footer>
      <Box
        sx={{
          display: { xs: 'none', md: 'flex' },
          maxWidth: '156px',
          height: '52px',
          mt: '50px',
        }}
      >
        <CButton onClick={onNext}>Proceed</CButton>
      </Box>
    </>
  );
}

export default Bottom;
