import to from 'await-to-js';
import { actions as jobActions } from './job.actions';
import { jobApiService } from '../../services/job/jobApiService';

const getAllJob = () => async (dispatch) => {
  dispatch(jobActions.getAllJobInitialized());

  const [err, data] = await to(jobApiService.getAllJob());
  if (err) {
    return;
  }

  dispatch(jobActions.getAllJobSuccessful(data));
};

const getJobInfo = (id) => async (dispatch) => {
  dispatch(jobActions.getJobInfoInitialized());

  const [err, data] = await to(jobApiService.getJobInfo(id));
  if (err) {
    return;
  }
  dispatch(jobActions.getJobInfoSuccessful(data));

  return data;
};

const getJob = (id) => async (dispatch) => {
  dispatch(jobActions.getJobInitialized());

  const [err, data] = await to(jobApiService.getJob(id));
  if (err) {
    return;
  }
  dispatch(jobActions.getJobSuccessful(data));

  return data.title;
};

const createJob = (body) => async (dispatch) => {
  dispatch(jobActions.createJobInitialized());

  const [err, data] = await to(jobApiService.createJob(body));
  if (err) {
    return;
  }
  dispatch(jobActions.createJobSuccessful(data));
  return data.id;
};

const update = (id, body) => async (dispatch) => {
  dispatch(jobActions.updateJobInitialized());

  const [err, data] = await to(jobApiService.update(id, body));

  if (err) {
    return;
  }
  dispatch(jobActions.updateJobSuccessful(data));
  return data;
};

const reset = (id, body) => async (dispatch) => {
  dispatch(jobActions.resetJobInitialized());

  const [err, data] = await to(jobApiService.reset(id, body));

  if (err) {
    return;
  }
  dispatch(jobActions.resetJobSuccessful(data));
  return data;
};

const deleteJob = (id) => async (dispatch) => {
  dispatch(jobActions.deleteJobInitialized(id));

  const [err, data] = await to(jobApiService.delete(id));
  if (err) {
    return;
  }
  if (data) {
    dispatch(jobActions.deleteJobSuccessful(id));
  }
};

export const thunks = {
  getAllJob,
  getJobInfo,
  getJob,
  createJob,
  update,
  reset,
  deleteJob,
};
