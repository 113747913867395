import {
  Box, Button, Pagination, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { thunks as jobThunks } from '../../store/job/job.thunks';
import { thunksProfile } from '../../store/profile/profile.thunks';
import { jobsSelector } from '../../store/job/job.selectors';
import { CustomFooterText } from '../../components/TextStyle/component/index.style';
import Footer from '../../components/Footer';
import usePagination from '../../components/Paginator';
import JobItem from './component/JobItem';
import HeaderJob from './component/HeaderJob';

function Jobs() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector(jobsSelector.jobs);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(null);

  useEffect(() => {
    dispatch(jobThunks.getAllJob());
    dispatch(thunksProfile.getProfile());
  }, [dispatch]);

  const PER_PAGE = 12;

  const count = Math.ceil(data.length / PER_PAGE);
  const pagination = usePagination(data, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    pagination.jump(p);
  };
  const jobsToShow = !search
    ? pagination.currentData()
    : pagination.currentData().filter((job) => job.title.match(search));
  return (
    <>
      <Box
        sx={{
          pt: { xs: '10px', md: '100px' },
          pb: { xs: '85px', md: '10px' },
          px: { xs: '30px', md: '108px' },
          height: '100%',
          overflowY: 'scroll',
        }}
      >
        <HeaderJob onChange={(e) => setSearch(e.target.value)} onClick={() => navigate('/app/createjob/')} />
        <Box>
          {
            jobsToShow.length ? jobsToShow.map((job) => (
              <JobItem job={job} key={job.id} />
            )) : <Typography>You have 0 Job Listings, Please Create a New Job</Typography>
          }
        </Box>

        <Box
          sx={{
            display: { xs: 'flex', md: 'flex' },
            justifyContent: 'center',
            mt: '20px',
            width: '100%',
          }}
        >
          {
            data.length ? (
              <Pagination
                count={count}
                size="large"
                page={page}
                siblingCount={0}
                variant="text"
                onChange={handleChange}
              />
            ) : <></>
          }

        </Box>
      </Box>

      <Footer>
        <CustomFooterText>Job List</CustomFooterText>
        <Button onClick={() => navigate('/app/createjob/')}>
          <AddIcon sx={{ color: '#fff', fontSize: '35px' }} />
        </Button>
      </Footer>
    </>
  );
}
export default Jobs;
