import { createAction } from '@reduxjs/toolkit';

const initVideoId = createAction('initVideoId');
const startUploading = createAction('startUploading');
const finishUploading = createAction('finishUploading');
const mergeVideo = createAction('mergeVideo');
const deleteVideoData = createAction('deleteVideoData');

const countChunks = createAction('countChunks');

const totalChunks = createAction('totalChunks');

export const actions = {
  initVideoId,
  startUploading,
  finishUploading,
  mergeVideo,
  countChunks,
  totalChunks,
  deleteVideoData,
};
