import {
  Box, IconButton, Menu, Tooltip, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { PrettoSlider } from './index.style';

function Rating({
  id, data, onRatingSubmit, onRatingChange,
}) {
  const [ratingOpen, setRatingOpen] = useState();

  const getRatingColor = (rating) => {
    if (rating >= 7) {
      return '#54BA8F';
    } if (rating >= 5) {
      return '#FFA800';
    }

    return '#EB5050';
  };

  const handleRatingClick = (event) => setRatingOpen(event.currentTarget);

  const handleRatingClose = () => {
    setRatingOpen(false);

    onRatingSubmit();
  };

  const handleRatingChange = (event, value) => onRatingChange(value);

  return (
    <Box sx={{ pb: '40px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '91px',
          height: '42px',
          background: getRatingColor(data.rating),
          borderRadius: '10px',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}
        >
          <Tooltip title="Rating">
            <IconButton
              onClick={handleRatingClick}
              size="small"
              aria-controls={ratingOpen ? `account-menu-${id}` : undefined}
              aria-haspopup="true"
              aria-expanded={ratingOpen ? 'true' : undefined}
            >
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontWeight: '700',
                  fontSize: '16px',
                  lineHeight: '22px',
                  textAlign: 'center',
                  color: '#FFFFFF',
                }}
              >
                {data.rating || 'Score'}
              </Typography>
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={ratingOpen}
          id={`account-menu-${id}`}
          open={!!ratingOpen}
          onClose={handleRatingClose}
          onClick={handleRatingClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
        >
          <Box sx={{ width: 300, px: 2 }}>
            <PrettoSlider
              value={typeof data.rating === 'number' ? data.rating : 0}
              aria-label="Always visible"
              onChange={handleRatingChange}
              step={1}
              min={1}
              max={10}
              valueLabelDisplay="on"
              color="secondary"
            />
          </Box>
        </Menu>
      </Box>
    </Box>
  );
}

export default Rating;
