import { actions as videoActions } from './video.actions';

const initialState = {
  video: {},
};

export const videoReducer = (state = initialState, action) => {
  switch (action.type) {
    case videoActions.initVideoId.type:
      return {
        ...state,
        video: {
          ...state.video,
          [action.payload.type]: { ...action.payload.video },
        },
      };
    case videoActions.countChunks.type: {
      const item = { ...state.video[action.payload] };
      item.count += 1;

      return { video: { ...state.video, [action.payload]: item } };
    }
    case videoActions.totalChunks.type: {
      const total = { ...state.video[action.payload.type] };
      total.total = action.payload.total;

      return { video: { ...state.video, [action.payload.type]: total } };
    }
    case videoActions.finishUploading.type: {
      const bool = { ...state.video[action.payload] };
      bool.uploaded = true;

      return { video: { ...state.video, [action.payload]: bool } };
    }
    case videoActions.deleteVideoData.type: {
      const video = { ...state.video };
      delete video[action.payload];

      return { ...state, video };
    }
    default:
      return state;
  }
};
