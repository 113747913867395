import { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import '../../index.style.css';
import videojs from 'video.js';
import 'video.js/dist/video-js.min.css';
import 'videojs-record/dist/css/videojs.record.css';
import 'videojs-record/dist/videojs.record.js';
import 'webrtc-adapter';

function Recorder({ onFinish, onRecording, noLimit }) {
  const videoJsOptions = {
    controls: true,
    bigPlayButton: false,
    width: 320,
    height: 240,
    fluid: true,
    responsive: true,
    controlBar: {
      fullscreenToggle: false,
      volumePanel: false,
      deviceButton: false,
      recordIndicator: false,
      cameraButton: false,
      pipToggle: false,
      recordToggle: true,
    },
    plugins: {
      record: {
        audio: true,
        video: true,
        debug: true,
        maxLength: 10 * 60,
      },
    },
  };

  if (!noLimit) {
    videoJsOptions.plugins.record.maxLength = 60;
  }

  const playerRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = videoRef.current;

      if (!videoElement) return;

      const player = (playerRef.current = videojs(
        videoElement,
        videoJsOptions,
        () => {
          videojs.log('player is ready');
        },
      ));

      const record = player.record();
      // Start camera stream
      record.getDevice();

      // Handlers
      player.on('startRecord', () => {
        onRecording && onRecording();
      });
      player.on('finishRecord', () => {
        record.stopDevice();
        onFinish({ file: player.recordedData, type: 'record' });
      });
    }
  }, [videoRef]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        const recorder = player.record();

        if (recorder) {
          recorder.stopDevice();
        }

        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <Box>
      <video
        ref={videoRef}
        playsInline
        className="video-js "
        style={{
          position: 'absolute',
          padding: '0',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
    </Box>
  );
}

export default Recorder;
