import { actions as formActions } from './form.actions';

const initialState = {
  job: null,
  applicationId: null,
  steps: [],
  activeStep: 0,
  videos: {},
};

export const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case formActions.formInitialized.type:
      return state;
    case formActions.formLoaded.type:
      return {
        ...state,
        job: action.payload.job,
        steps: action.payload.steps,
      };
    case formActions.formNextStep.type:
      return {
        ...state,
        activeStep: state.activeStep + 1,
      };
    case formActions.formPrevStep.type:
      return {
        ...state,
        activeStep: state.activeStep - 1,
      };
    case formActions.formApplicationCreated.type:
      return {
        ...state,
        applicationId: action.payload,
      };
    case formActions.formUploading.type:
      return {
        ...state,
        videos: {
          ...state.videos,
          [action.payload.param]: {
            uploading: true,
            url: '',
          },
        },
      };
    case formActions.formUploaded.type: {
      return {
        ...state,
        videos: {
          ...state.videos,
          [action.payload.param]: {
            uploading: false,
            url: action.payload.url,
          },
        },
      };
    }

    default:
      return state;
  }
};
