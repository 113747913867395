import React from 'react';
import { Box, Container } from '@mui/material';
import { Outlet } from 'react-router-dom';
import logo from './img/logo.png';
import logoDesk from './img/logoDesk.png';

function LoginContainer() {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        px: '30px',
      }}
    >
      <Box sx={{ pt: '63px', display: { xs: 'block', md: 'none' } }}>
        <img src={logo} alt="logo" />
      </Box>
      <Box sx={{ pt: '42px', display: { xs: 'none', md: 'block' } }}>
        <img src={logoDesk} alt="logo" />
      </Box>
      <Box sx={{ pt: '53px' }}>
        <Outlet />
      </Box>
    </Container>
  );
}

export default LoginContainer;
