import React from 'react';
import { Box } from '@mui/material';
import { FormDescription } from '../index.style';
import CInput from '../../../../components/CInput';
// import upload from './img/upload.png';
import { FormTitleForResume } from './index.style';
import Upload from './component/Upload';

function About({
  values, errors, touched, handleChange, setFieldValue,
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: { md: '330px', lg: '416px' },
        height: { md: '283px', lg: '357px' },
        maxHeight: { md: '283px', lg: '357px' },
      }}
    >
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ mb: '15px', mt: { xs: '5px', md: '0' }, px: '10px' }}>
          <FormTitleForResume sx={{ fontSize: { lg: '20px' } }}>
            Start Video Interview
          </FormTitleForResume>
        </Box>
        <Box sx={{ width: '100%' }}>
          <CInput
            error={!!errors.name && touched.name}
            name="name"
            onChange={handleChange}
            value={values.name}
            sx={{ mb: '10px' }}
            placeholder="Full Name"
          />

          <CInput
            error={!!errors.email && touched.email}
            name="email"
            onChange={handleChange}
            value={values.email}
            sx={{ mb: '10px' }}
            placeholder="Email"
          />

          <Upload
            onChange={(event) => setFieldValue('resume', event.currentTarget.files[0])}
            value={values.resume}
          />
        </Box>
        <FormDescription sx={{ fontSize: { lg: '16px' }, mt: '10px' }}>
          You can re-record and submit your VIDEO INTERVIEW when you are finished.
        </FormDescription>
      </Box>
    </Box>
  );
}

export default About;
