import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddIcon from '@mui/icons-material/Add';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import CInput from '../../components/CInput';
import Footer from '../../components/Footer';
import CButton from '../../components/СButton';
import TitleDesktop from '../../components/TitleDesktop';
import { thunks } from '../../store/job/job.thunks';

function CreateJob() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        height: '100%',
        pt: { xs: '10px', md: '100px' },
        pb: { xs: '85px', md: '10px' },
        px: { xs: '30px', md: '108px' },
      }}
    >
      <Formik
        initialValues={{
          title: '',
        }}
        onSubmit={async (values) => {
          const date = moment(values.expire).format('MM/DD/YYYY h:mm A');

          const id = await dispatch(thunks.createJob({ title: values.title, expire: date }));
          navigate(`/app/updatejob/${id}`);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} style={{ height: '100%' }}>
            <Box sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: { xs: 'center', md: 'flex-start' },
              flexDirection: 'column',
            }}
            >
              <TitleDesktop title="New job">
                <Box sx={{ minWidth: '186px' }}>
                  <CButton type="submit">
                    <AddIcon />
                    Complete
                  </CButton>
                </Box>
              </TitleDesktop>
              <Box sx={{ width: '100%', maxWidth: '780px', overflow: 'hidden' }}>
                <Box sx={{ p: 1 }}>
                  <CInput
                    fullWidth
                    id="title"
                    name="title"
                    autoFocus
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                    placeholder="Title"
                  />
                  {errors.title && touched.title && errors.title}
                </Box>
              </Box>
              <Footer>
                <Typography>New Job</Typography>
                <Button type="submit">
                  <ArrowForwardIosIcon sx={{ color: '#fff' }} />
                </Button>
              </Footer>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
}

export default CreateJob;
