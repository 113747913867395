import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CustomButtonStyle from './index.style';

function CButton({
  type, component, icon, onClick, height, children,
}) {
  return (
    <CustomButtonStyle
      type={type}
      fullWidth
      variant="contained"
      size="large"
      component={component}
      endIcon={icon ? <ArrowForwardIosIcon sx={{ fontSize: '1.7rem' }} /> : null}
      onClick={onClick}
      sx={{
        height,
        '&:focus': { border: 'none' },
      }}
    >
      {children}
    </CustomButtonStyle>
  );
}

export default CButton;
