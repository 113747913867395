import { createAction } from '@reduxjs/toolkit';

const getAllCandidateInitialized = createAction('getAllCandidate/initialized');
const getAllCandidateSuccessful = createAction('getAllCandidate/successful');

const getCandidateInitialized = createAction('getCandidate/initialized');
const getCandidateSuccessful = createAction('getCandidate/successful');

const approveCandidateInitialized = createAction(
  'approveCandidate/initialized',
);
const approveCandidateSuccessful = createAction('approveCandidate/successful');

const declineCandidateInitialized = createAction(
  'declineCandidate/initialized',
);
const declineCandidateSuccessful = createAction('declineCandidate/successful');

const deleteCandidateInitialized = createAction('deleteCandidate/initialized');
const deleteCandidateSuccessful = createAction('deleteCandidate/successful');

const createCandidateInitialized = createAction('createCandidate/Initialized');
const createCandidateSuccessful = createAction('createCandidate/successful');

const uploadResumeInitialized = createAction('uploadResume/Initialized');
const uploadResumeSuccessful = createAction('uploadResume/successful');

const updateCandidateInitialized = createAction('updateCandidate/Initialized');
const updateCandidateSuccessful = createAction('updateCandidate/successful');

const updateRatingCandidateInitialized = createAction(
  'updateRatingCandidate/Initialized',
);
const updateRatingCandidateSuccessful = createAction(
  'updateRatingCandidate/successful',
);

const updateNoteInitialized = createAction('updateNote/Initialized');
const updateNoteSuccessful = createAction('updateNote/successful');

export const actions = {
  getAllCandidateInitialized,
  getAllCandidateSuccessful,
  getCandidateInitialized,
  getCandidateSuccessful,
  approveCandidateInitialized,
  approveCandidateSuccessful,
  declineCandidateInitialized,
  declineCandidateSuccessful,
  deleteCandidateInitialized,
  deleteCandidateSuccessful,
  createCandidateInitialized,
  createCandidateSuccessful,
  uploadResumeInitialized,
  uploadResumeSuccessful,
  updateCandidateInitialized,
  updateCandidateSuccessful,
  updateRatingCandidateInitialized,
  updateRatingCandidateSuccessful,
  updateNoteInitialized,
  updateNoteSuccessful,
};
