import { Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import { CustomOptionText } from '../../../../index.style';

function ButtonItems({ onClick, onClick1, onClick2 }) {
  return (
    <Box sx={{ display: { xs: 'none', md: 'flex' }, flexWrap: 'wrap' }}>
      <Box
        sx={{ cursor: 'pointer' }}
        onClick={onClick}
      >
        <Box
          sx={{
            mx: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: '#54BA8F',
            borderRadius: '50%',
            width: '30px',
            height: '30px',
          }}
        >
          <AddIcon sx={{ color: '#FFF' }} />
        </Box>
        <CustomOptionText sx={{ color: '#54BA8F' }}> Invite</CustomOptionText>
      </Box>
      <Box
        sx={{ cursor: 'pointer' }}
        onClick={onClick1}
      >
        <Box
          sx={{
            mx: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: '#FFA800',
            borderRadius: '50%',
            width: '30px',
            height: '30px',
          }}
        >
          <ClearIcon sx={{ color: '#FFF' }} />
        </Box>
        <CustomOptionText sx={{ color: '#FFA800' }}>
          {' '}
          Decline
        </CustomOptionText>
      </Box>
      <Box
        sx={{ cursor: 'pointer' }}
        onClick={onClick2}
      >
        <Box
          sx={{
            mx: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: '#EB5050',
            borderRadius: '50%',
            width: '30px',
            height: '30px',
          }}
        >
          <DeleteIcon sx={{ color: '#FFF' }} />
        </Box>
        <CustomOptionText sx={{ color: '#EB5050' }}> Delete</CustomOptionText>
      </Box>
    </Box>
  );
}
export default ButtonItems;
