import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Header from './component/Header';
import { candidateSelectors } from '../../store/candidates/candidates.selectors';
import { thunks as candidateThunks } from '../../store/candidates/candidates.thunks';
import ItemQuestion from './component/ItemQuestion';
import Footer from '../../components/Footer';
import ItemFooter from './component/ItemFooter';
import 'react-toastify/dist/ReactToastify.css';
import Resume from './component/Resume';
import { jobsSelector } from '../../store/job/job.selectors';
import { thunks } from '../../store/job/job.thunks';
import MobileDescription from './component/MobileDescription';
import DesktopDescription from './component/DesktopDescription';
import { useConfirmation } from '../../hook/confirm';

function Candidate() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [note, setNote] = useState();
  const data = useSelector(candidateSelectors.candidates);
  const jobDate = useSelector(jobsSelector.job);
  const [application, setApplication] = useState(data);

  const { modal, show } = useConfirmation((data) => {
    if (data.type === 'invite') {
      dispatch(candidateThunks.approve(id));
    }
    if (data.type === 'decline') {
      dispatch(candidateThunks.decline(id));
    }
    if (data.type === 'delete') {
      dispatch(candidateThunks.remove(id));
    }
  });

  useEffect(() => {
    setApplication(data);

    if (data.jobId) {
      dispatch(thunks.getJob(data.jobId));
    }

    if (data.note !== note) {
      setNote(data.note);
    }
  }, [data]);

  const handleChange = (value) => {
    setNote(value);
  };

  useEffect(() => {
    dispatch(candidateThunks.get(id));

    setApplication(data);
  }, []);

  const handleRatingChange = (rating) => setApplication({ ...application, rating });

  const handleRatingSubmit = async () => {
    dispatch(candidateThunks.updateRating({ value: application.rating }, id));
  };

  const handleSave = async () => {
    dispatch(candidateThunks.updateNote(id, { note }));
  };

  return (
    <Box
      sx={{
        pt: { xs: '10px', md: '100px' },
        pb: { xs: '85px', md: '10px' },
        px: { xs: '30px', md: '108px' },
      }}
    >
      {modal}
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: { xs: 'center', md: 'stretch' },
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ position: 'absolute', left: '5%', top: '20px' }}>
          <Button
            onClick={() => navigate(`/app/candidates/all/${data.jobId}`)}
            sx={{ color: '#000', display: { md: 'none' } }}
          >
            <NavigateBeforeIcon sx={{ fontSize: '35px' }} />
          </Button>
        </Box>

        <MobileDescription data={data} />
        <Header
          data={data}
          onApprove={() => show({ type: 'invite' }, 'invite')}
          onDecline={() => show({ type: 'decline' }, 'decline')}
          onDelete={() => show({ type: 'delete' }, 'delete')}
        />

        <DesktopDescription
          data={data}
          data1={application}
          onRatingChange={handleRatingChange}
          onRatingSubmit={handleRatingSubmit}
          value={note}
          onChange={(e) => handleChange(e.target.value)}
          onClick={handleSave}
        />

        <ItemQuestion
          title="Intro"
          video={data.firstQuestion}
          desc={jobDate.firstDescription}
        />
        {data.resume ? <Resume resume={data.resume} /> : <></>}
        <ItemQuestion
          title="Question 1"
          video={data.secondQuestion}
          desc={jobDate.secondDescription}
        />
        <ItemQuestion
          title="Question 2"
          video={data.thirdQuestion}
          desc={jobDate.thirdDescription}
        />
        <ItemQuestion
          title="Question 3"
          video={data.fourthQuestion}
          desc={jobDate.fourthDescription}
        />
      </Box>
      <Footer>
        <ItemFooter
          color="#54BA8F"
          iconcolor="#54BA8F"
          onClick={() => show({ type: 'invite' }, 'invite')}
          name="Invite"
        >
          <AddCircleIcon sx={{ fontSize: '35px' }} />
        </ItemFooter>
        <ItemFooter
          color="#FFA800"
          iconcolor="#FFA800"
          onClick={() => show({ type: 'decline' }, 'decline')}
          name="Decline"
        >
          <CancelIcon sx={{ fontSize: '35px' }} />
        </ItemFooter>
        <ItemFooter
          onClick={() => show({ type: 'delete' }, 'delete')}
          name="Delete"
        >
          <DeleteForeverIcon sx={{ fontSize: '35px' }} />
        </ItemFooter>
      </Footer>
    </Box>
  );
}
export default Candidate;
