import { createAction } from '@reduxjs/toolkit';

const getJobInfoInitialized = createAction('getJobInfo/initialized');
const getJobInfoSuccessful = createAction('getJobInfo/successful');

const getAllJobInitialized = createAction('getAllJob/initialized');
const getAllJobSuccessful = createAction('getAllJob/successful');

const getJobInitialized = createAction('getJob/initialized');
const getJobSuccessful = createAction('getJob/successful');

const createJobInitialized = createAction('createJob/initialized');
const createJobSuccessful = createAction('createJob/successful');

const updateJobInitialized = createAction('updateJob/initialized');
const updateJobSuccessful = createAction('updateJob/successful');

const resetJobInitialized = createAction('resetVideoJob/initialized');
const resetJobSuccessful = createAction('resetVideoJob/successful');

const deleteJobInitialized = createAction('deleteJob/initialized');
const deleteJobSuccessful = createAction('deleteJob/successful');

export const actions = {
  getJobInfoInitialized,
  getJobInfoSuccessful,
  getAllJobInitialized,
  getAllJobSuccessful,
  getJobInitialized,
  getJobSuccessful,
  createJobInitialized,
  createJobSuccessful,
  updateJobInitialized,
  updateJobSuccessful,
  resetJobInitialized,
  resetJobSuccessful,
  deleteJobInitialized,
  deleteJobSuccessful,
};
